import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import {
  ObservationFormAnswerDTO,
  ReportFiltersDTO,
} from 'src/app/private/pages/reporting-page/filters/report-filters.dto';
import { TopicDTO } from 'src/app/private/shared/components/topic-select/topic-select.component';
import { CoachingSessionTypeDTO } from 'src/app/private/shared/dtos/coaching-session.dto';
import { Form } from 'src/app/private/shared/dtos/forms.dto';
import { DWCohort } from 'src/app/private/shared/types/responses/cohort.responses';
import { UserDTO } from '../../state/user/user.dto';
import { User } from '../../state/user/user.model';
import { dateTimeFromNgbDate } from '../../utilities/date-helpers';
import { booleanNumberFromBoolean } from '../../utilities/enums/boolean-number.enum';
import { DistrictDTO } from '../district.dto';
import { KpiReportFilter } from '../reports.dto';
import { SchoolDTO, SchoolYearDTO } from '../school.dto';

export const translateKpiReportFilterFromFilterDTO = (
  filterDTO: ReportFiltersDTO
): KpiReportFilter[] => {
  const returnArray: KpiReportFilter[] = [];
  Object.keys(filterDTO).forEach((key) => {
    /* eslint-disable-next-line default-case */
    switch (key) {
      case 'dateRangeFilter':
        returnArray.push({
          codename: 'daterange',
          title: 'Date Range',
          value: [
            dateTimeFromNgbDate(
              (filterDTO.dateRangeFilter as Array<NgbDate>)[0]
            ).toFormat('yyyy-MM-dd'),
            dateTimeFromNgbDate(
              (filterDTO.dateRangeFilter as Array<NgbDate>)[1]
            ).toFormat('yyyy-MM-dd'),
          ],
        });
        break;
      case 'logTypeFilter':
        returnArray.push({
          codename: 'session_type',
          title: 'Session Type',
          value: (filterDTO.logTypeFilter as CoachingSessionTypeDTO[]).map(
            (logType) => logType.id.toString()
          ),
        });
        break;
      case 'schoolYearFilter':
        if (filterDTO.schoolYearFilter) {
          returnArray.push({
            codename: 'school_year',
            title: 'School Year',
            value: [filterDTO.schoolYearFilter?.id.toString()],
            displayName: filterDTO.schoolYearFilter?.name,
          });
        }

        break;
      case 'schoolYearsFilter':
        returnArray.push({
          codename: 'school_year',
          title: 'School Year',
          value: (filterDTO.schoolYearsFilter as SchoolYearDTO[]).map((year) =>
            year.id.toString()
          ),
          displayName: (filterDTO.schoolYearsFilter as SchoolYearDTO[])
            .map((year) => year.name)
            .join(','),
        });
        break;
      case 'districtFilter':
        returnArray.push({
          codename: 'district',
          title: 'District',
          value: (filterDTO.districtFilter as DistrictDTO[]).map((district) =>
            district.id.toString()
          ),
          displayName: filterDTO.districtFilter
            ?.map((district) => district.title)
            .join(','),
        });
        break;
      case 'schoolFilter':
        if (filterDTO.schoolFilter) {
          returnArray.push({
            codename: 'school',
            title: 'School',
            value: (filterDTO.schoolFilter as SchoolDTO[]).map((school) =>
              school.id.toString()
            ),
            displayName: filterDTO.schoolFilter
              ?.map((school) => school.title)
              .join(','),
          });
        }
        break;
      case 'cohortFilter':
        returnArray.push({
          codename: 'cohort',
          title: 'Cohort',
          value: (filterDTO.cohortFilter as DWCohort[]).map((cohort) =>
            cohort.id.toString()
          ),
          displayName: (filterDTO.cohortFilter as DWCohort[])
            .map((cohort) => cohort.name)
            .join(','),
        });
        break;
      case 'assignedCoachFilter':
        returnArray.push({
          codename: 'assigned_coach',
          title: 'Assigned Coach',
          value: [(filterDTO.assignedCoachFilter as User).id.toString()],
          displayName: (filterDTO.assignedCoachFilter as User).fullName,
        });
        break;
      case 'coacheesFilter':
        returnArray.push({
          codename: 'user',
          title: 'Coachee',
          value: (filterDTO.coacheesFilter as UserDTO[]).map((coachee) =>
            coachee.id.toString()
          ),
          displayName: filterDTO.coacheesFilter
            ?.map(
              (coachee) =>
                `${coachee.profile.first_name} ${coachee.profile.last_name}`
            )
            .join(', '),
        });
        break;
      case 'formFilter':
        returnArray.push({
          codename: 'form',
          title: 'Form',
          value: [(filterDTO.formFilter as Form).id.toString()],
          displayName: (filterDTO.formFilter as Form).title,
        });
        break;
      case 'formAnswersFilter':
        returnArray.push({
          codename: 'form_response',
          value: (
            filterDTO.formAnswersFilter as ObservationFormAnswerDTO[]
          ).map((answer) => `${answer.questionId}question${answer.id}`),
        });
        break;
      case 'topicFilter':
        returnArray.push({
          codename: 'rubric',
          value: [(filterDTO.topicFilter as TopicDTO).id.toString()],
        });
        break;
      case 'hasOption':
        returnArray.push({
          codename: 'has_option',
          value: [
            booleanNumberFromBoolean(filterDTO.hasOption as boolean).toString(),
          ],
        });
        break;
      case 'isReviewed':
        returnArray.push({
          codename: 'is_reviewed',
          value: [
            booleanNumberFromBoolean(
              filterDTO.isReviewed as boolean
            ).toString(),
          ],
        });
        break;
    }
  });
  return returnArray;
};
