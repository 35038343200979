import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { UserLiteDTO } from 'src/app/common/state/user/user.dto';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import {
  dateFromUnixTimestamp,
  ngbDateStructFromUnixTimestamp,
} from 'src/app/common/utilities/date-helpers';
import { checkIfE2L } from 'src/app/common/utilities/role-helpers';
import { FileDTO } from '../../../dtos/file.dto';
import { UploadType } from '../../../enums/upload-type.enum';
import { AlertService } from '../../../services/alert/alert.service';
import { PlanActionItemFileService } from '../../../services/file/plan-action-item.file.service';
import { PlansPusherService } from '../../../services/plans/plans-pusher.service';
import { PlansService } from '../../../services/plans/plans.service';
import { PusherAssignmentChangesDTO } from '../../../services/plans/plans.service.dto';
import {
  AddAssignment,
  DeleteActionItem,
  DeleteAssignment,
  EditActionItem,
} from '../../../state/implementation-plan/implementation-plan.actions';
import { EditActionItemPayload } from '../../../types/payloads/plan.payloads';
import {
  ActionItemAPIResponse,
  DeliverableAPIResponse,
  StatusType,
} from '../../../types/responses/plan.responses';
import {
  DatepickerComponent,
  DatepickerOutput,
} from '../../datepicker/datepicker.component';
import { FileListComponent } from '../../file-management/file-list/file-list.component';
import {
  FileUploadComponent,
  UploadStyles,
} from '../../file-management/file-upload/file-upload.component';
import { ModalComponent } from '../../modals/modal/modal.component';
import {
  WysiwygEditorComponent,
  WysiwygEditorConfig,
} from '../../wysiwyg-editor/wysiwyg-editor.component';
import { CommentModelDTO } from '../plans-comments/plans-comments.dto';

@Component({
  selector: 'app-edit-action-item-modal',
  templateUrl: './edit-action-item-modal.component.html',
  styleUrl: './edit-action-item-modal.component.scss',
})
export class EditActionItemModalComponent
  implements OnInit, OnChanges, OnDestroy
{
  @ViewChild('modal')
  modal: ModalComponent;

  @ViewChild('dueDatePicker') dueDatePicker: DatepickerComponent;

  @ViewChild('wysiwyg') public editor: WysiwygEditorComponent;

  @ViewChild('uploadComponent') uploadComponent: FileUploadComponent;

  @ViewChild('fileListComponent') fileListComponent: FileListComponent;

  @Input() displayName: string;

  @Input() actionItem: ActionItemAPIResponse;

  @Input() phaseId: number;

  @Input() parentDeliverable: DeliverableAPIResponse;

  @Input() districtId: number;

  user: User;

  isE2L = false;

  subs: Subscription[] = [];

  form: FormGroup;

  formSubmitted = false;

  selectedUserIds: number[] = [];

  editActionItemPayload: EditActionItemPayload;

  defaultDueDate: number;

  datePickerMinDate: Date;

  datePickerMaxDate: Date;

  isLoading = false;

  editorConfig: WysiwygEditorConfig;

  uploadStyle = UploadStyles.SIMPLE;

  uploadType = UploadType.ACTION_ITEM_ATTACHMENT;

  attachments: FileDTO[] = [];

  commentRequest: Subscription;

  commentList: CommentModelDTO[];

  updatedContent = '';

  selectedStatus: StatusType;

  assignees: UserLiteDTO[] = [];

  constructor(
    private plansService: PlansService,
    private plansPusherService: PlansPusherService,
    private planActionItemFileService: PlanActionItemFileService,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    private store: Store
  ) {
    this.user = this.store.selectSnapshot(UserState.getUser) as User;
    this.isE2L = checkIfE2L(this.user);
  }

  ngOnInit(): void {
    this.editorConfig = {
      initialContent: '',
      editId: '',
    };

    this.plansPusherService.actionItemCommentNotifier.subscribe(() => {
      this.getComments();
    });
    this.plansPusherService.actionItemAttachmentNotifier.subscribe(() => {
      this.getAttachments();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.actionItem && changes['actionItem']) {
      if (
        changes['actionItem'].currentValue !==
        changes['actionItem'].previousValue
      ) {
        this.updateNotes(
          this.actionItem.content === null ? '' : this.actionItem.content
        );
        this.getComments();
        this.getAttachments();
        this.loadActionItemData();
      }
    }
  }

  get f() {
    return this.form.controls;
  }

  loadActionItemData() {
    if (this.actionItem) {
      this.form = this.formBuilder.group({
        title: [this.actionItem.title, Validators.required],
        description: [this.actionItem.description],
        status: [this.actionItem.status],
        dueDate: [dateFromUnixTimestamp(this.defaultDueDate * 1000)],
      });

      this.planActionItemFileService.setActionItemId(this.actionItem.id);
      this.selectedStatus = this.setLocalStatus(this.actionItem.status);
      this.setInitialDueDate(this.actionItem.due_date);
      this.assignees = [];
      this.actionItem.assignments.forEach((assignment) => {
        // Check if the user is already in the assignees array
        if (
          !this.assignees.some((assignee) => assignee.id === assignment.user.id)
        ) {
          this.assignees.push(assignment.user);
        }
      });

      this.datePickerMinDate = new Date(this.parentDeliverable.start_date);
      this.datePickerMinDate.setDate(this.datePickerMinDate.getDate() + 1);
      this.datePickerMaxDate = new Date(this.parentDeliverable.due_date);
      this.datePickerMaxDate.setDate(this.datePickerMaxDate.getDate() + 1);

      // This is for all changes after init when the editor now exists
      if (this.editor) {
        if (
          this.actionItem.content === undefined ||
          (this.actionItem.content === null && this.editor)
        ) {
          this.editor.editorContent = '';
        } else {
          this.editor.editorContent = this.actionItem.content;
        }
      } else {
        this.editorConfig.initialContent = this.updatedContent;
      }
    }
  }

  // eslint-disable-next-line class-methods-use-this
  setLocalStatus(status: string): StatusType {
    switch (status) {
      case 'Not Started':
        return StatusType.NOT_STARTED;
      case 'On Track':
        return StatusType.ON_TRACK;
      case 'Off Track':
        return StatusType.OFF_TRACK;
      case 'At Risk':
        return StatusType.AT_RISK;
      case 'Completed':
        return StatusType.COMPLETE;
      case 'Canceled':
        return StatusType.CANCELED;
      default:
        return StatusType.NOT_STARTED;
    }
  }

  openModal() {
    this.modal.open();
  }

  closeModal() {
    this.loadActionItemData();
    this.modal.close();
  }

  deleteActionItem(delId: number) {
    this.plansService.deleteActionItem(delId).subscribe((resp) => {
      if (resp) {
        this.alertService.showAlert('ActionItem Deleted');
        this.store.dispatch(
          new DeleteActionItem({
            id: this.actionItem.id,
            implementation_phase_id: this.actionItem.implementation_phase_id,
            implementation_deliverable_id:
              this.actionItem.implementation_deliverable_id,
          })
        );
        this.closeModal();
      }
    });
  }

  // ADD UPDATE ASSIGNEES
  addAssignee(newAssignee: UserLiteDTO | null) {
    if (
      newAssignee &&
      !this.assignees.some((assignee) => assignee.id === newAssignee.id)
    ) {
      this.assignees.push(newAssignee);
    }
  }

  deleteAssignee(assigneeId: number) {
    this.assignees = this.assignees.filter(
      (assignee) => assignee.id !== assigneeId
    );
  }

  // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-unused-vars
  onActionItemStatusChange(status: StatusType) {
    this.selectedStatus = status;
  }

  // SET DATES
  setInitialDueDate(end: string) {
    this.defaultDueDate = new Date(end).getTime() / 1000 + 60 * 60 * 24;

    if (this.dueDatePicker && this.defaultDueDate) {
      this.dueDatePicker.selectedDate = ngbDateStructFromUnixTimestamp(
        this.defaultDueDate
      );
    }
  }

  updateDueDate(times: DatepickerOutput[]) {
    const endDate = times[0].time / 1000;
    if (
      endDate &&
      endDate <= this.datePickerMaxDate.getTime() / 1000 &&
      endDate >= this.datePickerMinDate.getTime() / 1000 - 60 * 60 * 24
    ) {
      this.defaultDueDate = endDate;
    }
    if (this.dueDatePicker && this.defaultDueDate) {
      this.dueDatePicker.selectedDate = ngbDateStructFromUnixTimestamp(
        this.defaultDueDate
      );
    }
    this.f['dueDate'].setValue(
      dateFromUnixTimestamp(this.defaultDueDate * 1000)
    );

    this.cdr.detectChanges();
  }

  submitActionItemData() {
    this.formSubmitted = true;
    this.isLoading = true;

    this.editActionItemPayload = {
      title: this.f['title'].value,
      description: this.f['description'].value,
      status: this.selectedStatus,
      content: this.updatedContent || '',
      due_date: this.f['dueDate'].value,
    };

    this.plansService
      .updateActionItem(this.actionItem.id, this.editActionItemPayload)
      .subscribe((res) => {
        this.store.dispatch(new EditActionItem(res.item));
        this.modal.close();
        this.isLoading = false;
      });

    // If user was not already assigned to the action item, then assign the user
    this.assignees.forEach((assignee) => {
      if (
        !this.actionItem.assignments.some(
          (assignment) => assignment.user.id === assignee.id
        )
      ) {
        this.plansService
          .addActionItemAssignee(this.actionItem.id, assignee.id)
          .subscribe((response) => {
            const assigneePusher: PusherAssignmentChangesDTO = {
              for: 'implementation_action_item',
              for_id: this.actionItem.id,
              implementation_phase_id: this.actionItem.implementation_phase_id,
              implementation_deliverable_id:
                this.actionItem.implementation_deliverable_id,
              assignment: response.item,
            };
            this.store.dispatch(new AddAssignment(assigneePusher));
          });
      }
    });

    // If user was already assigned to the action item, but was removed during editing, delete the assignment
    this.actionItem.assignments.forEach((assignment) => {
      if (
        !this.assignees.some((assignee) => assignee.id === assignment.user.id)
      ) {
        this.plansService
          .deleteActionItemAssignee(this.actionItem.id, assignment.user.id)
          .subscribe((response) => {
            if (response) {
              const assigneePusher: PusherAssignmentChangesDTO = {
                for: 'implementation_action_item',
                for_id: this.actionItem.id,
                implementation_phase_id:
                  this.actionItem.implementation_phase_id,
                implementation_deliverable_id:
                  this.actionItem.implementation_deliverable_id,
                assignment,
              };
              this.store.dispatch(new DeleteAssignment(assigneePusher));
            }
          });
      }
    });
  }

  updateNotes(newNotes: string) {
    this.updatedContent = newNotes;
  }

  // ATTACHMENTS
  updateAttachments(file: FileDTO) {
    this.attachments.push(file);
    this.fileListComponent.fileListUpdate();
  }

  deleteAttachment(file: FileDTO) {
    this.attachments = this.attachments.filter(
      (attachment) => attachment.id !== file.id
    );
  }

  getAttachments() {
    this.plansService
      .getActionItemAttachments(this.actionItem.id)
      .subscribe((response) => {
        this.attachments = response;
      });
  }

  getComments() {
    if (this.commentRequest) this.commentRequest.unsubscribe();
    this.commentRequest = this.plansService
      .getComments('implementation_action_item', this.actionItem.id)
      .subscribe((response) => {
        if (response) {
          this.commentList = response.items;
        }
      });

    this.subs.push(this.commentRequest);
  }

  ngOnDestroy() {
    let sub = this.subs.pop();
    while (sub) {
      sub.unsubscribe();
      sub = this.subs.pop();
    }
  }
}
