import { Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngxs/store';
import { UserPermission } from 'src/app/common/state/user/permission/user-permission';
import { User } from 'src/app/common/state/user/user.model';
import { UserService } from 'src/app/common/state/user/user.service';
import { PaginationDTO } from 'src/app/common/types/responses/responses-template';
import { BooleanNumber } from 'src/app/common/utilities/enums/boolean-number.enum';
import { checkIfE2L } from 'src/app/common/utilities/role-helpers';
import { AppliedFiltersComponent } from 'src/app/private/shared/components/applied-filters/applied-filters.component';
import { FilterList } from 'src/app/private/shared/components/applied-filters/applied-filters.dto';
import { PdFilterModalComponent } from 'src/app/private/shared/components/modals/pd-filter-modal/pd-filter-modal.component';
import {
  PdListFilterDTO,
  PdSession,
  PdSettings,
} from 'src/app/private/shared/dtos/pd.dto';
import { PdService } from 'src/app/private/shared/services/pd/pd.service';

@Component({
  selector: 'app-catalog',
  templateUrl: './catalog.component.html',
  styleUrl: './catalog.component.scss',
})
export class CatalogPageComponent implements OnInit {
  @ViewChild('pdFilterModal') pdFilterModal: PdFilterModalComponent;

  @ViewChild('appliedFilters') appliedFilters: AppliedFiltersComponent;

  user: User;

  canCreate = false;

  sessions: PdSession[];

  settings: PdSettings;

  listMeta: PaginationDTO;

  isE2L: boolean;

  currentFilters: PdListFilterDTO = {
    from_date: null,
    to_date: null,
    keyword: '',
    facilitators: [],
    types: [],
    virtual: BooleanNumber.FALSE,
    onsite: BooleanNumber.FALSE,
    categories: [],
    per_page: 5,
    page: 1,
    include_completed: BooleanNumber.FALSE,
    competencyList: [],
  };

  constructor(
    private store: Store,
    private pdService: PdService,
    private userService: UserService
  ) {
    this.user = this.store.selectSnapshot((state) => state.user.user) as User;
  }

  ngOnInit() {
    this.getSessions();
    this.getSettings();
    this.checkUserPermissions();
    this.isE2L = checkIfE2L(this.user);
  }

  checkUserPermissions() {
    this.userService.fetchUserPermissions().subscribe((res) => {
      if (res) {
        this.canCreate = res.items.some(
          (item: { name: string }) =>
            item.name === UserPermission.CREATE_PD_SESSION
        );
      }
    });
  }

  getSessions() {
    this.pdService.getSessions(this.currentFilters).subscribe((resp) => {
      if (resp.items) {
        this.sessions = resp.items;
        this.listMeta = resp._meta;
      }
    });
  }

  getSettings() {
    this.pdService.getAdminSettings(this.user.district?.id).subscribe((res) => {
      if (res.item) {
        this.settings = res.item;
      }
    });
  }

  applyFilters(filters: PdListFilterDTO) {
    this.currentFilters = filters;
    this.getSessions();
  }

  removeFilter(keyName: string) {
    /* eslint-disable-next-line default-case */
    switch (keyName) {
      case 'date':
        this.currentFilters.from_date = null;
        this.currentFilters.to_date = null;
        this.appliedFilters.pdFromDate = null;
        this.appliedFilters.pdToDate = null;
        break;
      case 'keyword':
        this.currentFilters.keyword = '';
        break;
      case 'virtual':
        this.currentFilters.virtual = BooleanNumber.FALSE;
        break;
      case 'onsite':
        this.currentFilters.onsite = BooleanNumber.FALSE;
        break;
      case 'completed':
        this.currentFilters.include_completed = BooleanNumber.FALSE;
        break;
    }
    this.getSessions();
  }

  removeFilterFromList(filterList: FilterList) {
    /* eslint-disable-next-line default-case */
    switch (filterList.keyName) {
      case 'facilitators':
        if (this.currentFilters.facilitators) {
          this.currentFilters.facilitators =
            this.currentFilters.facilitators.filter(
              (item) => item !== filterList.value
            );
        }
        break;
      case 'types':
        if (this.currentFilters.types) {
          this.currentFilters.types = this.currentFilters.types.filter(
            (item) => item !== filterList.value
          );
        }
        break;
      case 'categories':
        if (this.currentFilters.categories) {
          this.currentFilters.categories =
            this.currentFilters.categories.filter(
              (item) => item !== filterList.value
            );
        }
        break;
      case 'competencies':
        if (this.currentFilters.competencyList) {
          this.currentFilters.competencyList =
            this.currentFilters.competencyList.filter(
              (competency) => competency !== filterList.value
            );
        }
        break;
    }
    this.getSessions();
  }
}
