import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({
  name: 'formatDateOrdinal',
})
export class FormatDateOrdinalPipe implements PipeTransform {
  // eslint-disable-next-line class-methods-use-this
  transform(value: number, outputFormat?: string): string {
    const day = DateTime.fromSeconds(value).day;

    function getOrdinal(number: number) {
      switch (number) {
        case 1:
        case 21:
        case 31:
          return 'st';
          break;
        case 2:
        case 22:
          return 'nd';
          break;
        case 3:
        case 23:
          return 'rd';
          break;
        default:
          return 'th';
      }
    }

    const ordinal = getOrdinal(day);

    if (outputFormat) {
      const date = DateTime.fromSeconds(value).toFormat(outputFormat);
      return `${date}${ordinal}`;
    }
    const date = DateTime.fromSeconds(value).toFormat('MMMM d');
    return `${date}${ordinal}`;
  }
}
