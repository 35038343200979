import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EnvironmentService } from 'src/app/common/services/environment/environment.service';
import { PdSession } from '../../../dtos/pd.dto';
import { PdService } from '../../../services/pd/pd.service';

@Component({
  selector: 'app-pd-list-item',
  templateUrl: './pd-list-item.component.html',
  styleUrl: './pd-list-item.component.scss',
})
export class PdListItemComponent implements OnInit {
  @Input() session: PdSession;

  @Input() context: 'normal' | 'copy' = 'normal';

  @Input() userId: number;

  @Output() readonly sessionCopied = new EventEmitter<PdSession>();

  public API_URL = EnvironmentService.apiUrl();

  isRegistered: boolean;

  isFacilitator: boolean;

  isComplete: number;

  sessionOverdue: boolean;

  courseLink: string;

  backgroundImage: string;

  constructor(private pdService: PdService) {}

  ngOnInit() {
    if (this.session && this.userId) {
      this.checkifSessionPast();
      this.isComplete = this.session.is_completed;
      if (this.session.facilitators && this.session.facilitators.length > 0) {
        this.isFacilitator = this.session.facilitators.some(
          (facilitator) => facilitator.facilitator_user_id === this.userId
        );
      }
      if (this.session.attendees && this.session.attendees.length > 0) {
        this.isRegistered = this.session.attendees.some(
          (attendee) => attendee.user_id === this.userId
        );
      }
      if (this.session.image) {
        this.backgroundImage = `url(${this.API_URL}${this.session.image})`;
      } else {
        this.backgroundImage = 'url(/assets/pd_list_item_default.png)';
      }
    }
  }

  register(sessionId: number) {
    this.pdService.registerSession(sessionId).subscribe(() => {
      this.isRegistered = true;
    });
  }

  unregister(sessionId: number) {
    this.pdService.unregisterSession(sessionId, this.userId).subscribe(() => {
      this.isRegistered = false;
    });
  }

  checkifSessionPast() {
    // allows users to register for full day of event
    const todaysDate = new Date();
    const sessionDate = new Date(this.session.end_datetime * 1000);
    sessionDate.setHours(25, 0, 0);
    this.sessionOverdue = todaysDate > sessionDate;
  }

  handleSelect(session: PdSession) {
    this.sessionCopied.next(session);
  }
}
