import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Store } from '@ngxs/store';
import { DateTime } from 'luxon';
import { debounceTime } from 'rxjs';
import { DistrictSimpleDto } from 'src/app/common/dtos/district.dto';
import { UserLiteDTO } from 'src/app/common/state/user/user.dto';
import { User } from 'src/app/common/state/user/user.model';
import { UserService } from 'src/app/common/state/user/user.service';
import {
  oneDecimalValidator,
  pdSessionTypes,
  positiveIntegerValidator,
  positiveValidator,
  valueLimitValidator,
  virtualOptions,
} from 'src/app/common/utilities/pd-helpers';
import { checkIfE2L } from 'src/app/common/utilities/role-helpers';
import { TimezoneLinkBack } from 'src/app/common/utilities/time-helpers';
import { CompetencySelectComponent } from 'src/app/private/shared/components/competency/competency-select/competency-select.component';
import {
  DatepickerComponent,
  DatepickerOutput,
} from 'src/app/private/shared/components/datepicker/datepicker.component';
import { getValidTimepickerValue } from 'src/app/private/shared/components/datepicker/datepicker.helpers';
import { DistrictSelectComponent } from 'src/app/private/shared/components/district-select/district-select.component';
import { FileListComponent } from 'src/app/private/shared/components/file-management/file-list/file-list.component';
import {
  FileUploadComponent,
  UploadStyles,
} from 'src/app/private/shared/components/file-management/file-upload/file-upload.component';
import { ModalComponent } from 'src/app/private/shared/components/modals/modal/modal.component';
import { WysiwygEditorComponent } from 'src/app/private/shared/components/wysiwyg-editor/wysiwyg-editor.component';
import { CompetencyDTO } from 'src/app/private/shared/dtos/competencies.dto';
import { FileDTO } from 'src/app/private/shared/dtos/file.dto';
import {
  PdCategoryOption,
  PdSession,
  PdSettings,
} from 'src/app/private/shared/dtos/pd.dto';
import { UploadType } from 'src/app/private/shared/enums/upload-type.enum';
import { getIcon } from 'src/app/private/shared/helpers/icon.utilities';
import { CompetenciesSelectService } from 'src/app/private/shared/services/competencies/competencies-select.service';
import { DistrictSearchService } from 'src/app/private/shared/services/district-search/district-search.service';
import { PdService } from '../../../shared/services/pd/pd.service';
import { CopyPdSessionModalComponent } from '../copy-pd-session-modal/copy-pd-session-modal.component';

@Component({
  selector: 'app-edit-pd-session-modal',
  templateUrl: './edit-pd-session-modal.component.html',
  styleUrl: './edit-pd-session-modal.component.scss',
})
export class EditPdSessionModalComponent implements OnInit {
  @ViewChild('modal')
  modal: ModalComponent;

  @Input() session: PdSession;

  @Output() readonly sessionUpdated = new EventEmitter<PdSession>();

  @ViewChild('copyPdSessionModal')
  copyPdSessionModal: CopyPdSessionModalComponent;

  @ViewChild('uploadComponent') uploadComponent: FileUploadComponent;

  @ViewChild('fileListComponent') fileListComponent: FileListComponent;

  @ViewChild('attendeeNotesEditor') attendeeNotesEditor: WysiwygEditorComponent;

  @ViewChild('dateAndTimePicker') dateAndTimePicker: DatepickerComponent;

  @ViewChild('selectCompetency') selectCompetency: CompetencySelectComponent;

  @ViewChild('shareWithDistrictSelect')
  shareWithDistrictSelect: DistrictSelectComponent;

  @ViewChild('excludeFromDistrictSelect')
  excludeFromDistrictSelect: DistrictSelectComponent;

  isUpdating = false;

  user: User;

  isE2L = false;

  canCreate = false;

  usersDistrictHasSessions = true;

  form: FormGroup;

  imagePath = '';

  uploadStyle = UploadStyles.SIMPLE;

  uploadType = UploadType.USER_UPLOAD;

  pdSessionTypes = pdSessionTypes;

  virtualOptions = virtualOptions;

  creditData: { creditType: string; creditAmount: number }[] = [];

  selectedCreditTypes: string[] = [];

  categoryData: { category: string; categoryOptions: PdCategoryOption }[] = [];

  selectedOptionsByCategory: { [category: string]: string[] } = {};

  settings: PdSettings;

  districtIds: number[] = [];

  currentCompetencies: CompetencyDTO[] = [];

  startDateTime: string;

  endDateTime: string;

  registrationMinDate: Date;

  todaysDate = DateTime.now().toJSDate();

  dateAndTimeInvalid = false;

  defaultTimezone: TimezoneLinkBack = TimezoneLinkBack.Central;

  facilitators: UserLiteDTO[] = [];

  errorMessage = '';

  debounceTime = 750;

  constructor(
    private store: Store,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private pdService: PdService,
    private competenciesSelectService: CompetenciesSelectService,
    private districtSearchService: DistrictSearchService,
    private cdr: ChangeDetectorRef
  ) {
    this.user = this.store.selectSnapshot((state) => state.user.user) as User;
    this.isE2L = checkIfE2L(this.user);
    if (this.user.district && !this.isE2L) {
      this.districtIds = [this.user.district.id];
    }
  }

  ngOnInit() {
    this.getSettings();
    this.populateForm();
    this.cdr.detectChanges();

    this.form
      .get('title')
      ?.valueChanges.pipe(debounceTime(this.debounceTime))
      .subscribe((value) => {
        if (value !== '') {
          this.updateTitle(value);
        }
      });
    this.form
      .get('shortDescription')
      ?.valueChanges.pipe(debounceTime(this.debounceTime))
      .subscribe((value) => {
        if (value !== '') {
          this.updateDescription(value);
        }
      });
    this.form
      .get('location')
      ?.valueChanges.pipe(debounceTime(this.debounceTime))
      .subscribe((value) => {
        this.updateLocation(value);
      });
    this.form
      .get('virtualLink')
      ?.valueChanges.pipe(debounceTime(this.debounceTime))
      .subscribe((value) => {
        this.updateVirtualLink(value);
      });
    this.form
      .get('attendanceCap')
      ?.valueChanges.pipe(debounceTime(this.debounceTime))
      .subscribe((value) => {
        const control = this.form.get('attendanceCap');
        if (control && !positiveIntegerValidator()(control)) {
          this.updateAttendanceCap(value);
        }
      });

    this.form.get('isShared')?.valueChanges.subscribe((value) => {
      const control = this.form.get('isShared');
      if (control) {
        this.updateIsShared(value);
      }
    });
  }

  closeModal() {
    this.sessionUpdated.emit(this.session);
    this.modal.close();
  }

  updateTitle(title: string) {
    this.isUpdating = true;
    this.pdService.updateSession(this.session.id, { title }).subscribe(() => {
      this.isUpdating = false;
    });
  }

  updateDescription(desc: string) {
    this.isUpdating = true;
    this.pdService
      .updateSession(this.session.id, { description: desc })
      .subscribe(() => {
        this.isUpdating = false;
      });
  }

  updateImage(file: FileDTO) {
    this.isUpdating = true;
    this.imagePath = getIcon(file.location);
    this.pdService
      .updateSession(this.session.id, { image: file.location })
      .subscribe(() => {
        this.isUpdating = false;
      });
  }

  deleteImage() {
    this.isUpdating = true;
    this.imagePath = '';
    this.pdService
      .updateSession(this.session.id, { image: '' })
      .subscribe(() => {
        this.isUpdating = false;
      });
  }

  updateType(type: string) {
    this.isUpdating = true;
    this.pdService.updateSession(this.session.id, { type }).subscribe(() => {
      this.isUpdating = false;
    });
  }

  updateIsVirtual(isVirtual: number) {
    this.isUpdating = true;
    this.pdService
      .updateSession(this.session.id, {
        is_virtual: isVirtual,
        location: isVirtual ? '' : this.session.location,
        virtual_link: isVirtual ? this.session.virtual_link : '',
      })
      .subscribe(() => {
        this.form.patchValue({ location: '' });
        this.isUpdating = false;
      });
  }

  updateDateTime(output: DatepickerOutput[]) {
    this.isUpdating = true;
    this.pdService
      .updateSession(this.session.id, {
        start_datetime: output[0].time / 1000,
        end_datetime: output[1].time / 1000,
      })
      .subscribe(() => {
        this.isUpdating = false;
      });
    if (this.dateAndTimeInvalid) {
      this.dateAndTimeInvalid = false;
    }
  }

  updateLocation(location: string) {
    this.isUpdating = true;
    this.pdService
      .updateSession(this.session.id, { location })
      .subscribe(() => {
        this.isUpdating = false;
      });
  }

  updateVirtualLink(link: string) {
    this.isUpdating = true;
    this.pdService
      .updateSession(this.session.id, { virtual_link: link || '' })
      .subscribe(() => {
        this.isUpdating = false;
      });
  }

  updateRegistrationFromDate(output: DatepickerOutput[]) {
    this.isUpdating = true;
    this.registrationMinDate = new Date(output[0].time);
    this.pdService
      .updateSession(this.session.id, {
        registration_start_datetime: output[0].time / 1000,
      })
      .subscribe(() => {
        this.isUpdating = false;
      });
  }

  updateRegistrationToDate(output: DatepickerOutput[]) {
    this.isUpdating = true;
    this.pdService
      .updateSession(this.session.id, {
        registration_end_datetime: output[0].time / 1000,
      })
      .subscribe(() => {
        this.isUpdating = false;
      });
  }

  updateAttendanceCap(cap: number) {
    this.isUpdating = true;
    this.pdService
      .updateSession(this.session.id, { max_registrations: cap })
      .subscribe(() => {
        this.isUpdating = false;
      });
  }

  updateIsShared(isShared: number) {
    this.isUpdating = true;
    this.pdService
      .updateSession(this.session.id, { is_shared: isShared ? 1 : 0 })
      .subscribe(() => {
        this.isUpdating = false;
      });
  }

  handleShareDistricts(incomingDistricts: DistrictSimpleDto[]): void {
    this.isUpdating = true;
    const incomingDistrictIds = incomingDistricts.map(
      (district) => district.id
    );

    if (this.session.sharing) {
      // Handle districts to be deleted (those in session.sharing but not in incomingDistricts)
      this.session.sharing.forEach((share) => {
        if (
          share.is_shared &&
          !incomingDistrictIds.includes(share.district_id)
        ) {
          this.pdService
            .deleteShare(this.session.id, share.id)
            .subscribe(() => {
              this.session.sharing = this.session.sharing?.filter(
                (s) => s.id !== share.id
              );
              this.isUpdating = false;
            });
        }
      });

      // Handle new districts to be added (those in incomingDistricts but not in session.sharing)
      incomingDistricts.forEach((district) => {
        // Check if the district is not already in this.session.sharing
        if (
          !this.session.sharing?.some(
            (share) => share.district_id === district.id
          )
        ) {
          // If it's not found, we proceed to share it
          this.pdService
            .shareWithDistrict(this.session.id, district.id)
            .subscribe((res) => {
              // Successfully shared, add it to the session's sharing list
              this.session.sharing?.push(res.item);
              this.isUpdating = false;
            });
        }
      });
    }

    // Update the exclude dropdown's internalSelectedDistrictList
    this.excludeFromDistrictSelect.internalSelectedDistrictList =
      this.excludeFromDistrictSelect.internalSelectedDistrictList.filter(
        (district) => !incomingDistrictIds.includes(district.id)
      );
  }

  handleExcludeDistricts(incomingDistricts: DistrictSimpleDto[]): void {
    this.isUpdating = true;
    const incomingDistrictIds = incomingDistricts.map(
      (district) => district.id
    );

    if (this.session.sharing) {
      // Handle districts to be deleted (those in session.sharing but not in incomingDistricts)
      this.session.sharing.forEach((share) => {
        if (
          !share.is_shared &&
          !incomingDistrictIds.includes(share.district_id)
        ) {
          this.pdService
            .deleteShare(this.session.id, share.id)
            .subscribe(() => {
              this.session.sharing = this.session.sharing?.filter(
                (s) => s.id !== share.id
              );
              this.isUpdating = false;
            });
        }
      });

      // Handle new districts to be added (those in incomingDistricts but not in session.sharing)
      incomingDistricts.forEach((district) => {
        if (
          !this.session.sharing?.some(
            (share) => share.district_id === district.id
          )
        ) {
          this.pdService
            .excludeFromDistrict(this.session.id, district.id)
            .subscribe((res) => {
              this.session.sharing?.push(res.item);
              this.isUpdating = false;
            });
        }
      });
    } else {
      this.isUpdating = false;
    }

    // Update the share dropdown's internalSelectedDistrictList
    this.shareWithDistrictSelect.internalSelectedDistrictList =
      this.shareWithDistrictSelect.internalSelectedDistrictList.filter(
        (district) => !incomingDistrictIds.includes(district.id)
      );
  }

  updateCompetencies(incomingComps: CompetencyDTO[] | null) {
    if (incomingComps) {
      this.isUpdating = true;
      // Extract egrowe_standard_ids from session competencies
      const sessionEgroweStandardIds =
        this.session.competencies?.map(
          (competency) => competency.egrowe_standard_id
        ) || [];

      // Find the single competency to add (if any)
      const toAdd = incomingComps.find(
        (competency) => !sessionEgroweStandardIds.includes(competency.id)
      );

      if (toAdd) {
        // Add the competency
        this.pdService
          .updateCompetency(this.session.id, {
            egrowe_standard_id: toAdd.id,
          })
          .subscribe((res) => {
            // Add the updated competency to the session
            this.session.competencies?.push(res.item);
            this.isUpdating = false;
          });
      } else {
        // Extract IDs from incomingComps
        const incomingIds = incomingComps.map((comp) => comp.id);

        // Find the single competency to delete (if any)
        const toDelete = this.session.competencies?.find(
          (comp) => !incomingIds.includes(comp.egrowe_standard_id)
        );

        if (toDelete && this.session.competencies) {
          // Delete the competency
          const index = this.session.competencies.indexOf(toDelete);
          this.pdService
            .deleteCompetency(this.session.id, toDelete.egrowe_standard_id)
            .subscribe(() => {
              // Remove the competency from session.competencies
              this.session.competencies?.splice(index, 1);
              this.isUpdating = false;
            });
        }
      }
    }
  }

  // FACILITATORS
  alreadyAdded(user: UserLiteDTO) {
    return this.facilitators.find((facilitator) => facilitator.id === user.id);
  }

  addFacilitator(facilitator: UserLiteDTO | null) {
    this.isUpdating = true;
    if (facilitator && !this.alreadyAdded(facilitator)) {
      this.pdService
        .addFacilitator(this.session.id, { user_id: facilitator.id })
        .subscribe((res) => {
          if (res) {
            this.facilitators.push(facilitator);
            this.isUpdating = false;
          }
        });
    }
  }

  removeFacilitator(facilitator: UserLiteDTO) {
    this.isUpdating = true;
    this.pdService
      .deleteFacilitator(this.session.id, facilitator.id)
      .subscribe((res) => {
        if (res) {
          this.facilitators = this.facilitators.filter(
            (fac) => fac.id !== facilitator.id
          );
          this.isUpdating = false;
        }
      });
  }

  get f() {
    return this.form.controls;
  }

  getSettings() {
    this.pdService.getAdminSettings(this.user.district?.id).subscribe((res) => {
      if (res.item) {
        this.settings = res.item;
      }
    });
  }

  // Populate form elements that are in FormGroup
  populateForm() {
    this.form = this.formBuilder.group({
      title: [this.session.title, Validators.required],
      shortDescription: [this.session.description, Validators.required],
      type: [this.session.type, Validators.required],
      virtual: [this.session.is_virtual ? 1 : 0, Validators.required],
      credits: this.session.credits
        ? this.formBuilder.array(
            this.session.credits.map((credit) =>
              this.formBuilder.group({
                creditType: [
                  credit.credit_type,
                  [Validators.required, this.creditTypeValidator()],
                ],
                creditAmount: [
                  credit.credit_amount,
                  [
                    Validators.required,
                    positiveValidator(),
                    valueLimitValidator(),
                    oneDecimalValidator(),
                  ],
                ],
              })
            )
          )
        : this.formBuilder.array([
            this.creditData.map((credit) => this.formBuilder.group(credit)),
          ]),
      categories: this.session.categories
        ? this.formBuilder.array(
            this.session.categories.map((category) =>
              this.formBuilder.group({
                category: [category.category, Validators.required],
                categoryOptions: [category.value, Validators.required],
              })
            )
          )
        : this.formBuilder.array(
            this.categoryData.map((category) =>
              this.formBuilder.group(category)
            )
          ),
      location: [this.session.location],
      virtualLink: [this.session.virtual_link],
      attendanceCap: [
        this.session.max_registrations,
        [Validators.required, positiveIntegerValidator()],
      ],
      isShared: new FormControl(this.session.is_shared),
    });
    this.categories.controls.forEach((control) => {
      // Watch for changes in the first dropdown (category)
      control.get('category')?.valueChanges.subscribe(() => {
        control.get('categoryOptions')?.reset(); // Reset the second dropdown when the category changes
        this.updateSelectedOptions(); // Update selected options globally
      });

      // Watch for changes in the second dropdown (categoryOptions)
      control.get('categoryOptions')?.valueChanges.subscribe(() => {
        this.updateSelectedOptions(); // Recompute selected options for all categories
      });
    });
    this.updateSelectedCreditTypes();
    this.updateSelectedOptions();

    this.populateCustomFormValues();
  }

  // Populates form values that are not in FormGroup (Custom datepickers, custom multi-select dropdowns, etc.)
  populateCustomFormValues() {
    this.startDateTime = getValidTimepickerValue(this.session.start_datetime);
    this.endDateTime = getValidTimepickerValue(this.session.end_datetime);

    this.registrationMinDate = new Date(this.session.start_datetime);

    if (this.session.image) {
      this.imagePath = getIcon(this.session.image);
    }

    if (this.session.competencies) {
      this.session.competencies.forEach((competency) => {
        this.competenciesSelectService
          .getCompetency(competency.egrowe_standard_id)
          .subscribe((comp) => {
            if (comp) {
              this.currentCompetencies.push(comp);
              if (this.selectCompetency) {
                this.selectCompetency.internalSelectedCompetencyList =
                  this.currentCompetencies;
              }
            }
          });
      });
    }

    if (this.session.sharing && this.session.is_shared) {
      this.session.sharing.forEach((share) => {
        this.districtSearchService
          .getDistrict(share.district_id)
          .subscribe((district) => {
            if (district) {
              if (share.is_shared === 1) {
                if (this.shareWithDistrictSelect)
                  this.shareWithDistrictSelect.internalSelectedDistrictList.push(
                    district
                  );
              } else if (this.excludeFromDistrictSelect)
                this.excludeFromDistrictSelect.internalSelectedDistrictList.push(
                  district
                );
            }
          });
      });
    }

    if (this.session.facilitators) {
      this.session.facilitators.forEach((facilitator) => {
        this.userService
          .fetchUserById(facilitator.facilitator_user_id)
          .subscribe((res) => {
            if (res) {
              this.facilitators.push(res);
            }
          });
      });
    }
  }

  // CREDITS
  get credits(): FormArray {
    return this.form.get('credits') as FormArray;
  }

  addCredit() {
    this.credits.push(
      this.formBuilder.group({
        creditType: [null, [Validators.required, this.creditTypeValidator()]],
        creditAmount: [0, [Validators.required, positiveValidator()]],
      })
    );
    this.watchCreditTypeChanges();
  }

  updateCreditType(creditType: string, index: number) {
    this.isUpdating = true;
    const matchingCredit = this.session.credits?.at(index);

    if (matchingCredit) {
      this.pdService
        .updateCredit(matchingCredit.id, {
          credit_type: creditType,
          credit_amount: matchingCredit.credit_amount,
        })
        .subscribe((res) => {
          if (res) {
            this.isUpdating = false;
            this.updateSelectedCreditTypes();
          }
        });
    } else {
      this.pdService
        .createCredit(this.session.id, {
          credit_type: creditType,
          credit_amount: 0,
        })
        .subscribe((res) => {
          if (res) {
            this.isUpdating = false;
            this.session.credits?.push(res.item);
          }
        });
    }
  }

  updateCreditAmount(event: Event, index: number) {
    const newValue = parseFloat(
      parseFloat((event.target as HTMLInputElement).value).toFixed(1)
    );
    const control = this.credits.at(index).get('creditAmount');
    if (control) {
      const overLimitValidationResult = valueLimitValidator()(control);
      const positiveValidationResult = positiveValidator()(control);
      const validDecimalValidationResult = oneDecimalValidator()(control);

      if (
        newValue >= 0 &&
        !positiveValidationResult &&
        !overLimitValidationResult &&
        !validDecimalValidationResult
      ) {
        this.isUpdating = true;
        const matchingCredit = this.session.credits?.at(index);

        if (matchingCredit) {
          this.pdService
            .updateCredit(matchingCredit.id, {
              credit_type: matchingCredit.credit_type,
              credit_amount: newValue,
            })
            .subscribe((res) => {
              if (res) {
                this.isUpdating = false;
                this.updateSelectedCreditTypes();
              }
            });
        } else {
          this.isUpdating = false;
        }
      }
    }
  }

  deleteCredit(index: number) {
    this.isUpdating = true;
    this.credits.removeAt(index);
    this.updateSelectedCreditTypes();

    const matchingCredit = this.session.credits?.at(index);
    if (matchingCredit) {
      this.pdService.deleteSessionCredit(matchingCredit.id).subscribe((res) => {
        if (res) {
          this.session.credits?.splice(index, 1);
          this.isUpdating = false;
        }
      });
    } else {
      this.isUpdating = false;
    }
  }

  watchCreditTypeChanges() {
    this.credits.valueChanges.subscribe(() => {
      this.updateSelectedCreditTypes();
    });
  }

  updateSelectedCreditTypes() {
    this.selectedCreditTypes = [];
    this.credits.controls.forEach((group) => {
      const creditType = group.get('creditType')?.value;
      if (creditType && creditType !== null) {
        this.selectedCreditTypes.push(creditType);
      }
    });
  }

  isCreditTypeSelected(creditTitle: string, currentIndex: number): boolean {
    const isSelected = this.selectedCreditTypes.includes(creditTitle);
    const currentControl = this.credits.at(currentIndex).get('creditType');
    return isSelected && currentControl?.value !== creditTitle;
  }

  // CATEGORIES
  get categories(): FormArray {
    return this.form.get('categories') as FormArray;
  }

  addCategory() {
    const newCategoryGroup = this.formBuilder.group({
      category: [null, Validators.required],
      categoryOptions: [null, Validators.required],
    });

    // Watch for changes in the first dropdown (category)
    newCategoryGroup.get('category')?.valueChanges.subscribe(() => {
      newCategoryGroup.get('categoryOptions')?.reset(); // Reset the second dropdown when the category changes
      this.updateSelectedOptions(); // Update selected options globally
    });

    // Watch for changes in the second dropdown (categoryOptions)
    newCategoryGroup.get('categoryOptions')?.valueChanges.subscribe(() => {
      this.updateSelectedOptions(); // Recompute selected options for all categories
    });

    this.categories.push(newCategoryGroup);
  }

  updateCategory(category: string, index: number) {
    this.isUpdating = true;
    const matchingCategory = this.session.categories?.at(index);

    if (
      matchingCategory &&
      this.categories.at(index).get('categoryOption')?.value
    ) {
      this.pdService
        .updateCategory(matchingCategory.id, {
          category,
          value: matchingCategory.value,
        })
        .subscribe((res) => {
          if (res) {
            this.isUpdating = false;
            this.updateSelectedOptions();
          }
        });
    } else {
      this.isUpdating = false;
    }
  }

  updateCategoryOption(categoryOption: string, index: number) {
    this.isUpdating = true;
    const matchingCategory = this.session.categories?.at(index);
    const payload = {
      category: this.categories.at(index).get('category')?.value,
      value: categoryOption,
    };

    if (matchingCategory) {
      this.pdService
        .updateCategory(matchingCategory.id, payload)
        .subscribe((res) => {
          if (res) {
            this.isUpdating = false;
            this.updateSelectedOptions();
          }
        });
    } else {
      this.pdService
        .createCategory(this.session.id, payload)
        .subscribe((res) => {
          if (res) {
            this.isUpdating = false;
            this.session.categories?.push(res.item);
          }
        });
    }
  }

  deleteCategory(index: number) {
    this.isUpdating = true;
    this.categories.removeAt(index);
    this.updateSelectedOptions(); // Update selected options after deletion
    if (this.session.categories?.[index]) {
      this.pdService
        .deleteSessionCategory(this.session.categories[index].id)
        .subscribe((res) => {
          if (res) {
            this.session.categories?.splice(index, 1);
            this.isUpdating = false;
          }
        });
    } else {
      this.isUpdating = false;
    }
  }

  updateSelectedOptions() {
    this.selectedOptionsByCategory = {}; // Reset the selected options tracking

    this.categories.controls.forEach((control) => {
      const category = control.get('category')?.value;
      const option = control.get('categoryOptions')?.value;

      if (category && option) {
        if (!this.selectedOptionsByCategory[category]) {
          this.selectedOptionsByCategory[category] = [];
        }

        if (!this.selectedOptionsByCategory[category].includes(option)) {
          this.selectedOptionsByCategory[category].push(option);
        }
      }
    });
  }

  getFilteredOptions(categoryTitle: string): PdCategoryOption[] {
    // Return options relevant to the selected category and not already selected for it
    const category = this.settings.categories.find(
      (cat) => cat.title === categoryTitle
    );

    if (!category) return []; // No options if the category is invalid or not found

    return category.options.filter(
      (option) =>
        !this.selectedOptionsByCategory[categoryTitle]?.includes(
          option.option_value
        ) // Exclude already-selected options
    );
  }

  // eslint-disable-next-line class-methods-use-this
  creditTypeValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      return value === 'Select a credit type'
        ? { invalidCreditType: true }
        : null;
    };
  }
}
