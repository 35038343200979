<div *ngIf="category" class="category-card mt-4">
  <div class="category-title py-2 px-3 d-flex justify-content-between">
    <span>
      {{ category.title }}
    </span>
    <button
      class="btn-delete"
      ngbTooltip="Delete Category"
      #tooltip="ngbTooltip"
      (click)="tooltip.close()"
      [autoClose]="'outside'"
      [ngbPopover]="deleteCategoryTemplate"
      [popoverTitle]="'Delete Category'"
      #popover="ngbPopover">
      <i class="bi bi-trash"></i>
    </button>
    <ng-template #deleteCategoryTemplate>
      Are you sure you want to delete this category?
      <div class="text-end mt-2">
        <button
          type="button"
          class="btn btn-secondary btn-sm px-3 m-1"
          (click)="popover.close()">
          No
        </button>
        <button
          type="button"
          class="btn btn-primary btn-sm px-3 m-1"
          (click)="deleteCategory(); popover.close()"
          href="javascript:void(0)">
          Yes
        </button>
      </div>
    </ng-template>
  </div>
  <div class="category-body py-3 px-4">
    <ng-container *ngIf="category.options.length">
      <div class="options-header fw-700 pb-1">Options</div>
      <div
        class="category-option p-2 d-flex justify-content-between"
        *ngFor="let option of category.options">
        <span class="option-title">
          {{ option.option_value }}
        </span>
        <button
          class="btn-delete"
          ngbTooltip="Delete Option"
          #tooltip="ngbTooltip"
          (click)="tooltip.close()"
          [autoClose]="'outside'"
          [ngbPopover]="deleteOptionTemplate"
          [popoverTitle]="'Delete Option'"
          #popover="ngbPopover">
          <i class="bi bi-trash"></i>
        </button>
        <ng-template #deleteOptionTemplate>
          Are you sure you want to delete this option?
          <div class="text-end mt-2">
            <button
              type="button"
              class="btn btn-secondary btn-sm px-3 m-1"
              (click)="popover.close()">
              No
            </button>
            <button
              type="button"
              class="btn btn-primary btn-sm px-3 m-1"
              (click)="deleteOption(option); popover.close()"
              href="javascript:void(0)">
              Yes
            </button>
          </div>
        </ng-template>
      </div>
    </ng-container>
    <ng-container *ngIf="!addOptionMode; else createOption">
      <button class="btn fw-700 px-0" (click)="turnOnAddOptionMode()">
        + Create Option
      </button>
    </ng-container>
    <ng-template #createOption>
      <div class="d-flex align-items-center mt-2 position-relative">
        <input
          #addOptionInput
          class="form-control"
          [(ngModel)]="addOptionValue"
          (keydown.enter)="addOption()"
          placeholder="New Option"
          type="text" />
        <ng-container *ngIf="addOptionValue">
          <i
            role="button"
            class="bi bi-plus-lg add-icon p-1"
            (click)="addOption()"></i>
        </ng-container>
      </div>
    </ng-template>
  </div>
</div>
