import { Injectable, Output } from '@angular/core';
import { Store } from '@ngxs/store';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { DistrictSimpleDto } from 'src/app/common/dtos/district.dto';
import { SchoolDTO, SchoolYearDTO } from 'src/app/common/dtos/school.dto';
import { translateKpiReportFilterFromFilterDTO } from 'src/app/common/dtos/translators/report-filters.translator';
import { APICoreService } from 'src/app/common/services/api-core/api-core.service';
import {
  UserRole,
  compareRoles,
} from 'src/app/common/state/user/role/user-role';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import {
  ObservationFormAnswerDTO,
  ReportFiltersDTO,
} from 'src/app/private/pages/reporting-page/filters/report-filters.dto';
import {
  FilterList,
  FilterType,
} from '../../components/applied-filters/applied-filters.dto';
import { Form } from '../../dtos/forms.dto';
import { AlertService } from '../alert/alert.service';
import { SchoolSearchService } from '../school-search/school-search.service';
import { SchoolYearService } from '../school-year/school-year.service';
import {
  CURRENT_SCHOOL_YEAR,
  getDistrictSchoolYearFromUser,
} from '../school-year/school-year.utilities';
import { ReportSessionDetailsService } from './report-session-details.service';
import { ReportService } from './report.service';

@Injectable({
  providedIn: 'root',
})
export class ReportPageFiltersService {
  // When subscribing to this, make sure to pipe(skip(1), ...)
  // This will ignore the inital value (will be empty or previous page filter)
  @Output() readonly filters: BehaviorSubject<ReportFiltersDTO>;

  currentFilters: ReportFiltersDTO;

  currentFiltersDictionary: { [key: string]: ReportFiltersDTO } = {};

  schoolYears: SchoolYearDTO[];

  district: DistrictSimpleDto;

  user: User;

  canSeeAllSchools = false;

  limitedSchools: SchoolDTO[] = [];

  viewingAllSchools = false;

  reportName: string;

  outputFilterChange(): void {
    if (!this.canSeeAllSchools && this.limitedSchools.length === 0) {
      return;
    }

    // If the user removes all districts, we add the default back
    if (this.currentFilters.districtFilter?.length === 0) {
      this.currentFilters.districtFilter = [this.district];
    }

    // If the user removes all school years, we add the default back (current school year)
    const currentYear = this.schoolYears.find(
      (year) => year.id === CURRENT_SCHOOL_YEAR.id
    ) as SchoolYearDTO;
    if (this.currentFilters.schoolYearsFilter?.length === 0) {
      this.currentFilters.schoolYearsFilter = [currentYear];
    }

    // If the user can't see all schools and removes the school filter, add it back
    if (!this.canSeeAllSchools) {
      if (
        !this.currentFilters.schoolFilter ||
        this.currentFilters.schoolFilter?.length === 0
      ) {
        this.currentFilters.schoolFilter = this.limitedSchools.slice();
        this.viewingAllSchools = true;
      } else if (
        this.currentFilters.schoolFilter.length === this.limitedSchools.length
      ) {
        this.viewingAllSchools = true;
      } else {
        this.viewingAllSchools = false;
      }
    } else {
      this.viewingAllSchools = false;
    }

    // If there are form answers but no form, remove the form answers filter; or, if form answers do not have same form ID as form
    if (
      this.currentFilters.formAnswersFilter &&
      this.currentFilters.formAnswersFilter.length > 0
    ) {
      if (
        (this.currentFilters.formAnswersFilter &&
          !this.currentFilters.formFilter) ||
        this.currentFilters.formFilter?.id !==
          (
            this.currentFilters.formAnswersFilter as ObservationFormAnswerDTO[]
          )[0].formId
      ) {
        delete this.currentFilters.formAnswersFilter;
      }
    }

    this.filters.next(this.currentFilters);

    if (this.reportName) {
      this.currentFiltersDictionary[this.reportName] = this.currentFilters;
    }
  }

  constructor(
    private store: Store,
    private schoolSearch: SchoolSearchService,
    private schoolYearService: SchoolYearService,
    private apiCoreService: APICoreService,
    private reportLogDetailsService: ReportSessionDetailsService,
    private reportService: ReportService,
    private alertService: AlertService
  ) {
    this.filters = new BehaviorSubject<ReportFiltersDTO>(
      {} as ReportFiltersDTO
    );

    this.user = this.store.selectSnapshot(UserState.getUser) as User;
    this.canSeeAllSchools = compareRoles(
      [UserRole.DISTRICT_ADMIN, UserRole.E2L_EMPLOYEE],
      this.user.roles
    );

    this.district = this.user.district as DistrictSimpleDto;
    this.schoolYearService.schoolYears.subscribe((schoolYears) => {
      this.schoolYears = schoolYears;
    });
  }

  setReportName(reportName: string) {
    if (this.reportName === reportName) {
      return;
    }
    this.reportName = reportName;
    if (!this.currentFiltersDictionary[reportName]) {
      // First load for given page
      this.getDistrictSchoolYear();
      this.getCoachSchools();
      this.currentFiltersDictionary[reportName] = this.currentFilters;
    } else {
      this.currentFilters = this.currentFiltersDictionary[reportName];
      this.outputFilterChange();
    }
  }

  setSelectedForm(selectedForm: Form): void {
    this.currentFilters.formFilter = selectedForm;
    this.outputFilterChange();
  }

  getDistrictTopics(
    districtIds: number[]
  ): Observable<{ title: string; id: number }[]> {
    const districtIdsToString = districtIds.join('&district[]=');
    return this.apiCoreService
      .getRequest(
        `reporting/dimension?dimensionName=rubric&district[]=${districtIdsToString}`
      )
      .pipe(
        map((res) =>
          res.items.map((rubric: { id: string; name: string }) => ({
            id: rubric.id,
            title: rubric.name,
          }))
        )
      );
  }

  getDistrictSchoolYear(): void {
    this.user = this.store.selectSnapshot(UserState.getUser) as User;
    this.currentFilters = { districtFilter: [this.district] };

    const schoolYear = getDistrictSchoolYearFromUser(this.user);
    if (this.reportName === 'badges_earned_growth') {
      this.currentFilters.schoolYearsFilter = [schoolYear];
    } else {
      this.currentFilters.schoolYearFilter = schoolYear;
    }

    this.currentFilters.reportName = this.reportName;

    if (this.canSeeAllSchools || this.limitedSchools.length > 0) {
      this.outputFilterChange();
    }
  }

  getCoachSchools() {
    if (this.canSeeAllSchools) {
      return;
    }

    if (this.limitedSchools.length === 0) {
      this.schoolSearch
        .getAssignedSchools(this.user.id)
        .subscribe((schools) => {
          if (schools.length === 0 && this.user.school) {
            this.limitedSchools = [this.user.school];
          } else {
            this.limitedSchools = [this.user.school, ...schools];
          }
          this.currentFilters.schoolFilter = this.limitedSchools.slice();
          this.viewingAllSchools = true;
          this.outputFilterChange();
        });
    }
  }

  removeFilter(filter: keyof ReportFiltersDTO): void {
    if (
      this.currentFilters[filter] === undefined ||
      this.currentFilters[filter] === null
    )
      return;
    delete this.currentFilters[filter];
    this.outputFilterChange();
  }

  removeFilterFromList(filterList: FilterList) {
    switch (filterList.keyName) {
      case 'schoolFilter':
        if (this.currentFilters.schoolFilter) {
          this.currentFilters.schoolFilter =
            this.currentFilters.schoolFilter.filter(
              (item) => item !== filterList.value
            );
          this.outputFilterChange();
        }
        break;
      case 'schoolYearsFilter':
        if (this.currentFilters.schoolYearsFilter) {
          this.currentFilters.schoolYearsFilter =
            this.currentFilters.schoolYearsFilter.filter(
              (item) => item !== filterList.value
            );
          this.outputFilterChange();
        }
        break;
      case 'districtFilter':
        if (this.currentFilters.districtFilter) {
          this.currentFilters.districtFilter =
            this.currentFilters.districtFilter.filter(
              (item) => item !== filterList.value
            );
          this.handleDistrictRemoval(filterList.value);
          this.outputFilterChange();
        }
        break;
      case 'formAnswerFilter':
        if (this.currentFilters.formAnswersFilter) {
          this.currentFilters.formAnswersFilter =
            this.currentFilters.formAnswersFilter.filter(
              (item) => item !== filterList.value
            );
          this.outputFilterChange();
        }
        break;
      case 'logTypeFilter':
        if (this.currentFilters.logTypeFilter) {
          this.currentFilters.logTypeFilter =
            this.currentFilters.logTypeFilter.filter(
              (item) => item !== filterList.value
            );
          this.outputFilterChange();
        }
        break;
      case 'coacheesFilter':
        if (this.currentFilters.coacheesFilter) {
          this.currentFilters.coacheesFilter =
            this.currentFilters.coacheesFilter.filter(
              (item) => item !== filterList.value
            );
          this.outputFilterChange();
        }
        break;
      case 'cohortFilter':
        if (this.currentFilters.cohortFilter) {
          this.currentFilters.cohortFilter =
            this.currentFilters.cohortFilter.filter(
              (item) => item !== filterList.value
            );
          this.outputFilterChange();
        }
        break;
      default:
        break;
    }
  }

  handleDistrictRemoval(filter: FilterType) {
    const district = filter as DistrictSimpleDto;
    this.currentFilters.schoolFilter = this.currentFilters.schoolFilter?.filter(
      (school) => school.district_id !== district.id
    );
  }

  queueReportDownload(reportName: string) {
    let report = { title: '', report: '' };
    let downloadFilters = {} as ReportFiltersDTO;

    switch (reportName) {
      case 'gi_growth':
        report = { title: 'GI Growth', report: 'giGrowth' };
        downloadFilters = this.currentFiltersDictionary[reportName];
        break;
      case 'session_details':
        report = { title: 'Session Details', report: 'logDetails' };
        downloadFilters = this.currentFiltersDictionary[reportName];
        break;
      case 'observation_reports':
        report = {
          title: 'Observation Forms Download',
          report: 'observationFormsDownload',
        };
        downloadFilters = this.currentFiltersDictionary[reportName];
        break;
      case 'badges_earned_raw':
        report = { title: 'Raw Badge Data', report: 'badgeDataDownloadRaw' };
        downloadFilters = this.currentFiltersDictionary['badges_earned_growth'];
        break;
      case 'microcredential_badge_summary':
        report = {
          title: 'Microcredential Badge Summary',
          report: 'badgeDataDownloadCompetency',
        };
        downloadFilters = this.currentFiltersDictionary['badges_earned_growth'];
        break;
      case 'strand_badge_summary':
        report = {
          title: 'Strand Badge Summary',
          report: 'badgeDataDownloadStrand',
        };
        downloadFilters = this.currentFiltersDictionary['badges_earned_growth'];
        break;
      default:
        return;
    }

    this.reportLogDetailsService
      .queueDownloadReport(
        translateKpiReportFilterFromFilterDTO(downloadFilters),
        report
      )
      .subscribe({
        complete: () => {},
        error: () => {
          // eslint-disable-next-line no-console
          console.log('search returned 0 results');
        },
      });
  }
}
