<div *ngIf="credits" class="credit-card mt-4">
  <div class="credit-title py-2 px-3">Credit Types</div>
  <div class="credit-body py-3 px-4">
    <ng-container *ngIf="credits.length">
      <div class="options-header fw-700 pb-1">Options</div>
      <div
        class="credit-option p-2 d-flex justify-content-between"
        *ngFor="let credit of credits">
        <span class="credit-option-title">
          {{ credit.title }}
        </span>
        <button
          class="btn-delete"
          ngbTooltip="Delete Option"
          #tooltip="ngbTooltip"
          (click)="tooltip.close()"
          [autoClose]="'outside'"
          [ngbPopover]="deleteOptionTemplate"
          [popoverTitle]="'Delete Option'"
          #popover="ngbPopover">
          <i class="bi bi-trash"></i>
        </button>
        <ng-template #deleteOptionTemplate>
          Are you sure you want to delete this option?
          <div class="text-end mt-2">
            <button
              type="button"
              class="btn btn-secondary btn-sm px-3 m-1"
              (click)="popover.close()">
              No
            </button>
            <button
              type="button"
              class="btn btn-primary btn-sm px-3 m-1"
              (click)="deleteCredit(credit.id); popover.close()"
              href="javascript:void(0)">
              Yes
            </button>
          </div>
        </ng-template>
      </div>
    </ng-container>
    <ng-container *ngIf="!addCreditMode; else createOption">
      <button class="btn fw-700 px-0" (click)="turnOnAddOptionMode()">
        + Create Option
      </button>
    </ng-container>
    <ng-template #createOption>
      <div class="d-flex align-items-center mt-2 position-relative">
        <input
          #addCreditInput
          class="form-control"
          [(ngModel)]="addCreditValue"
          (keydown.enter)="addCredit()"
          placeholder="New Option"
          type="text" />
        <ng-container *ngIf="addCreditValue">
          <i
            role="button"
            class="bi bi-plus-lg add-icon p-1"
            (click)="addCredit()"></i>
        </ng-container>
      </div>
    </ng-template>
  </div>
</div>
