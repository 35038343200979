import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { SchoolDTO } from 'src/app/common/dtos/school.dto';
import { UserRole } from 'src/app/common/state/user/role/user-role';
import { UserLiteDTO } from 'src/app/common/state/user/user.dto';
import { User } from 'src/app/common/state/user/user.model';
import { UserService } from 'src/app/common/state/user/user.service';
import { UserState } from 'src/app/common/state/user/user.state';
import { FEATURE_FLAGS } from 'src/app/private/shared/services/unleash/unleash.helpers';
import { UnleashService } from 'src/app/private/shared/services/unleash/unleash.service';
import { userFromDTO } from '../../../common/state/user/user.utilities';
import {
  TopicDTO,
  TopicSelectComponent,
} from '../../shared/components/topic-select/topic-select.component';
import { PorfolioMetaDataDTO } from '../../shared/dtos/portfolio.dto';
import { PortfolioService } from '../../shared/services/portfolio/portfolio.service';
import { SchoolSearchService } from '../../shared/services/school-search/school-search.service';
import { CURRENT_SCHOOL_YEAR } from '../../shared/services/school-year/school-year.utilities';

@Component({
  selector: 'app-portfolio-view-page',
  templateUrl: './portfolio-view-page.component.html',
  styleUrls: ['./portfolio-view-page.component.scss'],
})
export class PortfolioViewPageComponent implements OnInit {
  currentUser: User;

  isE2L = false;

  isCollapsed = true;

  profileUser: User;

  portfolioData: PorfolioMetaDataDTO;

  permissionEnabled = false;

  searchBarEnabled = false;

  portfolioLoaded = false;

  districtSearchId: number | null = null;

  topics: TopicDTO[];

  selectedTopic: number;

  userCohorts: string[] = [];

  portfolioLink: string;

  viewingEvidenceId: number;

  currentSchoolYearId: number;

  userSchools: SchoolDTO[];

  pdEnabled = false;

  @ViewChild('topicSelect') topicSelect: TopicSelectComponent;

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private portfolioService: PortfolioService,
    private schoolSearchService: SchoolSearchService,
    private featureFlagService: UnleashService
  ) {
    this.currentUser = this.store.selectSnapshot(UserState.getUser) as User;
    this.isE2L =
      this.currentUser?.roles.includes(UserRole.E2L_EMPLOYEE) ?? false;
    this.currentSchoolYearId = CURRENT_SCHOOL_YEAR.id;
    this.pdEnabled = this.featureFlagService.isEnabled(
      FEATURE_FLAGS.pdManagement
    );
  }

  ngOnInit(): void {
    this.getEvidenceData();
    this.getProfileData();
  }

  getEvidenceData() {
    this.route.queryParams.subscribe((params) => {
      if (params['evidence_id']) {
        this.viewingEvidenceId = parseInt(params['evidence_id']);
      }
    });
  }

  getProfileData() {
    this.route.params.subscribe((url) => {
      if (url['userId']) {
        this.userService.fetchUserById(url['userId']).subscribe(
          (res) => {
            if (res) {
              this.profileUser = userFromDTO(res);
              this.setupProfile();
              this.getUserSchoolsFull();
            }
          },
          (error) => {
            if (error.status === 404) {
              this.router.navigate(['/portfolio']);
            }
          }
        );
      } else {
        this.profileUser = this.currentUser;
        this.setupProfile();
        this.getUserSchoolsFull();
      }
    });
  }

  getUserSchoolsFull() {
    if (
      this.profileUser.userSchools &&
      this.profileUser.userSchools.length > 0
    ) {
      this.schoolSearchService
        .getAssignedSchools(this.profileUser.id)
        .subscribe((resp: SchoolDTO[]) => {
          this.userSchools = resp;
        });
    }
  }

  setupProfile() {
    if (this.topicSelect) this.topicSelect.reset();
    this.portfolioLink = `/portfolio/${this.profileUser.id}/badges`;
    this.getPortfolioData(this.profileUser.id);
    this.userService.fetchCohorts(this.profileUser.id).subscribe((cohorts) => {
      cohorts.forEach((cohort) => {
        this.userCohorts.push(cohort.tag);
      });
    });
    this.getRubricData();
  }

  getRubricData() {
    if (this.profileUser) {
      this.portfolioService
        .getUserRubrics(this.profileUser.id)
        .subscribe((res) => {
          // DO NOT INCLUDE CALIBRATION RUBRICS
          this.topics = res.items.filter(
            (item: TopicDTO) => item.is_calibration === 0
          );
        });
    }
  }

  getPortfolioData(userId: number, rubricIds?: number[]) {
    this.portfolioService.getPortfolioData(userId, rubricIds).subscribe(
      (res) => {
        this.portfolioData = res;
        this.portfolioData.evidenceProgress.percent = Math.round(
          this.portfolioData.evidenceProgress.percent * 100
        );
        this.permissionEnabled = true;
        this.getSearchbarPermissions();
        this.portfolioLoaded = true;
      },
      (error) => {
        if (error.status === 404) {
          this.router.navigate(['/portfolio']);
        } else {
          this.portfolioLoaded = true;
        }
      }
    );
  }

  filterTopic(topic: TopicDTO) {
    if (this.profileUser) {
      if (topic === undefined) {
        this.getPortfolioData(this.profileUser.id);
      } else {
        this.getPortfolioData(this.profileUser.id, [topic.id]);
      }
    }
    this.selectedTopic = topic.id;
  }

  getSearchbarPermissions() {
    if (
      this.currentUser.roles.includes(
        UserRole.COACH ||
          UserRole.DISTRICT_ADMIN ||
          UserRole.OPERATOR ||
          UserRole.E2L_EMPLOYEE ||
          UserRole.ESUITE_ADMIN ||
          UserRole.SCHOOL_ADMIN
      )
    ) {
      this.searchBarEnabled = true;
    }
  }

  handleUserSearchOutput(user: UserLiteDTO | null) {
    if (user) {
      this.router.navigate([`/portfolio/${user.id}`]);
    } else {
      this.router.navigate(['/portfolio']);
    }
  }
}
