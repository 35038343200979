import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

// For select dropdowns on creating & editing session
export const pdSessionTypes = [
  { label: 'Workshop', value: 'Workshop' },
  { label: 'Course', value: 'Course' },
  { label: 'Webinar', value: 'Webinar' },
];

export const virtualOptions = [
  { label: 'Virtual', value: 1 },
  { label: 'Onsite', value: 0 },
];

// Check that number is above 0
// eslint-disable-next-line class-methods-use-this
export function positiveValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (value === null || value === undefined || value === '') {
      return null; // Don't validate empty values (use `Validators.required` for that)
    }

    const isPositive = value > 0;
    return isPositive ? null : { nonNegative: true };
  };
}

// Check that number is above 0 and a whole number
// eslint-disable-next-line class-methods-use-this
export function positiveIntegerValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (value === null || value === undefined || value === '') {
      return null; // Don't validate empty values (use `Validators.required` for that)
    }

    const isPositiveInteger = Number.isInteger(value) && value > 0;
    return isPositiveInteger ? null : { nonNegativeInteger: true };
  };
}

// Check that number is less than the limit
// eslint-disable-next-line class-methods-use-this
export function valueLimitValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (value === null || value === undefined || value === '') {
      return null; // Don't validate empty values (use `Validators.required` for that)
    }

    const isValid = value <= 99;
    return isValid ? null : { overLimit: true };
  };
}

// Check that number does not exceed 1 decimal point
// eslint-disable-next-line class-methods-use-this
export function oneDecimalValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (value === null || value === undefined || value === '') {
      return null; // Don't validate empty values (use `Validators.required` for that)
    }
    const isValid = /^-?\d+(\.\d{1})?$/.test(value.toString());
    return isValid ? null : { invalidDecimal: true };
  };
}
