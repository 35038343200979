<div class="filter-bar-container d-flex align-items-center">
  <div class="filter-bar">
    <span class="filters-label">Applied Filters:</span>&nbsp; &nbsp;
    <ng-container *ngIf="canFilterByDistrict && appliedFilters.districtFilter">
      <div
        class="badge badge-item"
        *ngFor="let district of appliedFilters.districtFilter">
        <span class="fw-800"><i class="bi bi-geo-alt"></i> District: </span
        >{{ district.title }}
        <i
          *ngIf="appliedFilters.districtFilter.length > 1"
          (click)="
            setReportName();
            filterService.removeFilterFromList({
              keyName: 'districtFilter',
              value: district
            })
          "
          class="bi bi-x"></i>
      </div>
    </ng-container>
    <ng-container *ngIf="reportName !== 'badge_progress'">
      <ng-container
        *ngIf="
          reportName === 'badges_earned_growth' &&
          appliedFilters.schoolYearsFilter
        ">
        <div
          class="badge badge-item"
          *ngFor="let year of appliedFilters.schoolYearsFilter">
          <span class="fw-800"
            ><i class="bi bi-calendar"></i> School Year: </span
          >{{ year.name }}
          <i
            class="bi bi-x"
            *ngIf="appliedFilters.schoolYearsFilter.length > 1"
            (click)="
              setReportName();
              filterService.removeFilterFromList({
                keyName: 'schoolYearsFilter',
                value: year
              })
            "></i>
        </div>
      </ng-container>
      <ng-container *ngIf="appliedFilters.schoolYearFilter">
        <div class="badge badge-item">
          <span class="fw-800"
            ><i class="bi bi-calendar"></i> School Year: </span
          >{{ appliedFilters.schoolYearFilter.name }}
        </div>
      </ng-container>
    </ng-container>
    <div
      *ngIf="
        appliedFilters.topicFilter &&
        (reportName === 'badge_progress' ||
          reportName === 'evidence_report' ||
          reportName === 'badges_earned_growth')
      "
      class="badge badge-item removable">
      <span class="fw-800"><i class="bi bi-calendar"></i> Topic: </span
      >{{ appliedFilters.topicFilter.title }}
      <i
        class="bi bi-x"
        (click)="
          setReportName(); filterService.removeFilter('topicFilter')
        "></i>
    </div>
    <ng-container *ngIf="appliedFilters.schoolFilter">
      <div
        class="badge badge-item removable"
        *ngIf="this.filterService.viewingAllSchools"
        (click)="setReportName(); showModal = true">
        <span class="fw-800"><i class="bi bi-building"></i> School: </span>
        All Assigned Schools
      </div>
      <ng-container *ngIf="!this.filterService.viewingAllSchools">
        <div
          class="badge badge-item removable"
          *ngFor="let school of appliedFilters.schoolFilter">
          <span class="fw-800"><i class="bi bi-building"></i> School: </span>
          {{ school.title }}
          <i
            class="bi bi-x"
            *ngIf="!filterService.viewingAllSchools"
            (click)="
              setReportName();
              filterService.removeFilterFromList({
                keyName: 'schoolFilter',
                value: school
              })
            "></i>
        </div>
      </ng-container>
    </ng-container>
    <ng-container
      *ngIf="
        appliedFilters.hasOption !== undefined &&
        appliedFilters.hasOption !== null &&
        reportName === 'evidence_report'
      ">
      <div class="badge badge-item removable">
        <span class="fw-800"
          ><i class="bi bi-journal"></i> Attached to Option:
        </span>
        {{ appliedFilters.hasOption ? "Yes" : "No" }}
        <i
          (click)="setReportName(); filterService.removeFilter('hasOption')"
          class="bi bi-x"></i>
      </div>
    </ng-container>
    <ng-container
      *ngIf="
        appliedFilters.isReviewed !== undefined &&
        appliedFilters.isReviewed !== null &&
        reportName === 'evidence_report'
      ">
      <div class="badge badge-item removable">
        <span class="fw-800"
          ><i class="bi bi-clipboard-check"></i> Reviewed Status:
        </span>
        {{ appliedFilters.isReviewed ? "Reviewed" : "Not Reviewed" }}
        <i
          (click)="setReportName(); filterService.removeFilter('isReviewed')"
          class="bi bi-x"></i>
      </div>
    </ng-container>
    <ng-container *ngIf="appliedFilters.formAnswersFilter">
      <div
        class="badge badge-item removable"
        *ngFor="let formAnswer of appliedFilters.formAnswersFilter">
        <span class="fw-800"
          ><i class="bi bi-journal-text"></i> Form Answer:
        </span>
        {{ formAnswer.title }}
        <i
          (click)="
            setReportName();
            filterService.removeFilterFromList({
              keyName: 'formAnswersFilter',
              value: formAnswer
            })
          "
          class="bi bi-x"></i>
      </div>
    </ng-container>
    <ng-container *ngIf="appliedFilters.logTypeFilter">
      <div
        class="badge badge-item removable"
        *ngFor="let logType of appliedFilters.logTypeFilter">
        <span class="fw-800"><i class="bi bi-journal"></i> Session Type: </span>
        {{ logType.title }}
        <i
          (click)="
            setReportName();
            filterService.removeFilterFromList({
              keyName: 'logTypeFilter',
              value: logType
            })
          "
          class="bi bi-x"></i>
      </div>
    </ng-container>
    <ng-container *ngIf="appliedFilters.cohortFilter">
      <div
        *ngFor="let cohort of appliedFilters.cohortFilter"
        class="badge badge-item removable">
        <span class="fw-800"><i class="bi bi-people"></i> Cohort: </span
        >{{ cohort.name }}
        <i
          class="bi bi-x"
          (click)="
            setReportName();
            filterService.removeFilterFromList({
              keyName: 'cohortFilter',
              value: cohort
            })
          "></i>
      </div>
    </ng-container>
    <div
      *ngIf="appliedFilters.assignedCoachFilter"
      class="badge badge-item removable">
      <span class="fw-800"><i class="bi bi-person"></i> Coach: </span
      >{{ appliedFilters.assignedCoachFilter.profile.first_name }}
      {{ appliedFilters.assignedCoachFilter.profile.last_name }}
      <i
        class="bi bi-x"
        (click)="
          setReportName(); filterService.removeFilter('assignedCoachFilter')
        "></i>
    </div>
    <div
      *ngIf="appliedFilters.dateRangeFilter"
      class="badge badge-item removable">
      <span class="fw-800"><i class="bi bi-calendar2-week"></i> Dates: </span
      >{{ appliedFilters.dateRangeFilter[0] | formatNgbDate }} -
      {{ appliedFilters.dateRangeFilter[1] | formatNgbDate }}
      <i
        class="bi bi-x"
        (click)="
          setReportName(); filterService.removeFilter('dateRangeFilter')
        "></i>
    </div>
    <ng-container *ngIf="appliedFilters.coacheesFilter">
      <div
        class="badge badge-item removable"
        *ngFor="let coachee of appliedFilters.coacheesFilter">
        <span class="fw-800"><i class="bi bi-journal"></i> Coachee: </span>
        {{ coachee.profile.first_name }} {{ coachee.profile.last_name }}
        <i
          (click)="
            setReportName();
            filterService.removeFilterFromList({
              keyName: 'coacheesFilter',
              value: coachee
            })
          "
          class="bi bi-x"></i>
      </div>
    </ng-container>
    <button
      type="button"
      class="btn btn-link"
      (click)="setReportName(); showModal = true">
      Edit Filters
    </button>
    <app-report-filter-modal
      (modalClosing)="showModal = false"
      [showModal]="showModal"
      [canFilterByDistrict]="canFilterByDistrict"
      [reportName]="reportName"
      #modal></app-report-filter-modal>
  </div>
</div>
