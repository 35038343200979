import { DragDropModule } from '@angular/cdk/drag-drop';
import { DecimalPipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule } from '@angular/router';
import { FullCalendarModule } from '@fullcalendar/angular';
import {
  NgbCollapseModule,
  NgbModule,
  NgbPopoverModule,
  NgbProgressbar,
} from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsModule } from '@ngxs/store';
import * as Sentry from '@sentry/angular-ivy';
import { IntercomModule } from '@supy-io/ngx-intercom';
import { NgxParticlesModule } from '@tsparticles/angular';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import 'froala-editor/js/plugins.pkgd.min';
import { NgxEchartsModule } from 'ngx-echarts';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CheckActionItemWithinYearPipe } from './common/pipes/actionitem-visibility.pipe';
import { ArraySortPipe } from './common/pipes/array-sort.pipe';
import { CheckDeliverablesWithinYearPipe } from './common/pipes/deliverable-visibility.pipe';
import { FormatActivityFeedDateTime } from './common/pipes/format-activity-feed-datetime';
import { FormatDateMMDDYYPipe } from './common/pipes/format-date-mmddyy';
import { FormatDateOrdinalPipe } from './common/pipes/format-date-ordinal.pipe';
import { FormatDatePipe } from './common/pipes/format-date.pipe';
import { FormatDateTimePipe } from './common/pipes/format-datetime.pipe';
import { FormatDistanceToNowPipe } from './common/pipes/format-distance-to-now.pipe';
import { FormatNgbDatePipe } from './common/pipes/format-ngbdate.pipe';
import { FormatTimePipe } from './common/pipes/format-time.pipe';
import { FullNameProfilePipe } from './common/pipes/fullname-profile.pipe';
import { ConvertToLowercasePipe } from './common/pipes/lowercase.pipe';
import { ShowDateTimezonePipe } from './common/pipes/show-date-timezone.pipe';
import { StripHtmlPipe } from './common/pipes/strip-html.pipe';
import { TimezoneLinkBackPipe } from './common/pipes/timezonelinkback.pipe';
import { TruncatePhrasePipe } from './common/pipes/truncate-phrase.pipe';
import { ChatBotService } from './common/services/chat-bot/chat-bot.service';
import { EnvironmentService } from './common/services/environment/environment.service';
import { ErrorHandlerService } from './common/services/error-handler/error-handler.service';
import { UserState } from './common/state/user/user.state';
import { ApiAuthInterceptor } from './common/utilities/api-auth.interceptor';
import { GrowelabLayoutComponent } from './private/layout/growelab-layout/growelab-layout.component';
import { SidebarComponent } from './private/layout/sidebar/sidebar.component';
import { AddDistrictComponent } from './private/pages/add-district/add-district.component';
import { AllCelebrationsPageComponent } from './private/pages/all-celebrations-page/all-celebrations-page.component';
import { CalendarPageComponent } from './private/pages/calendar-page/calendar-page.component';
import { CoachFeedbackPageComponent } from './private/pages/coach-feedback-page/coach-feedback-page.component';
import { CoachListPageComponent } from './private/pages/coach-list-page/coach-list-page.component';
import { CoacheeEvidencePageComponent } from './private/pages/coachee-evidence-page/coachee-evidence-page.component';
import { CoacheeListPageComponent } from './private/pages/coachee-list-page/coachee-list-page.component';
import { FilterTableSearch } from './private/pages/coachee-list-page/search-filter.pipe';
import { CoachingCompetenciesPageComponent } from './private/pages/coaching-competencies-page/coaching-competencies-page.component';
import { CoachingLogInfoPageComponent } from './private/pages/coaching-log-info-page/coaching-log-info-page.component';
import { CoachingLogPageComponent } from './private/pages/coaching-log-page/coaching-log-page.component';
import { CoachingSessionsPageComponent } from './private/pages/coaching-sessions-page/coaching-sessions-page.component';
import { CohortsPageComponent } from './private/pages/cohorts-page/cohorts-page.component';
import { ConfigurationPageComponent } from './private/pages/configuration-page/configuration-page.component';
import { CoachDashboardComponent } from './private/pages/dashboard/coach-dashboard/coach-dashboard.component';
import { DashboardComponent } from './private/pages/dashboard/dashboard.component';
import { LeaderDashboardComponent } from './private/pages/dashboard/leader-dashboard/leader-dashboard.component';
import { LearnerDashboardComponent } from './private/pages/dashboard/learner-dashboard/learner-dashboard.component';
import { EditDistrictComponent } from './private/pages/edit-district/edit-district.component';
import { CreateFormPageComponent } from './private/pages/forms/create-form-page/create-form-page.component';
import { EditFormPageComponent } from './private/pages/forms/edit-form-page/edit-form-page.component';
import { FormsListPageComponent } from './private/pages/forms/forms-list-page/forms-list-page.component';
import { GeneralLogInfoPageComponent } from './private/pages/general/general-log-info-page/general-log-info-page.component';
import { GeneralLogPageComponent } from './private/pages/general/general-log-page/general-log-page.component';
import { ImplementationPlanDetailsPageComponent } from './private/pages/implementation-plan-details-page/implementation-plan-details-page.component';
import { ImplementationPlanPageComponent } from './private/pages/implementation-plan-page/implementation-plan-page.component';
import { ImplementationPlanSharingPageComponent } from './private/pages/implementation-plan-sharing-page/implementation-plan-sharing-page.component';
import { ImplementationStatusReportsPageComponent } from './private/pages/implementation-status-reports-page/implementation-status-reports-page.component';
import { MyResourcesPageComponent } from './private/pages/my-resources-page/my-resources-page.component';
import { ObservationFormPageComponent } from './private/pages/observation/observation-form-page/observation-form-page.component';
import { ObservationInfoPageComponent } from './private/pages/observation/observation-info-page/observation-info-page.component';
import { ObservationSummaryPageComponent } from './private/pages/observation/observation-summary-page/observation-summary-page.component';
import { PdAdminPageComponent } from './private/pages/pd-admin-page/pd-admin-page.component';
import { PermissionDeniedPageComponent } from './private/pages/permission-denied/permission-denied.component';
import { PlansPageComponent } from './private/pages/plans-page/plans-page.component';
import { PortfolioBadgesPageComponent } from './private/pages/portfolio-badges-page/portfolio-badges-page.component';
import { PortfolioViewPageComponent } from './private/pages/portfolio-view-page/portfolio-view-page.component';
import { ReportingBadgeProgressComponent } from './private/pages/reporting-badge-progress/reporting-badge-progress.component';
import { ReportingDownloadComponent } from './private/pages/reporting-download-page/reporting-download.component';
import { ReportingEvidenceComponent } from './private/pages/reporting-evidence/reporting-evidence.component';
import { FormAnswerSelectComponent } from './private/pages/reporting-observations/form-answer-select/form-answer-select.component';
import { ReportingObservationsComponent } from './private/pages/reporting-observations/reporting-observations.component';
import { AbsenceTypesTableComponent } from './private/pages/reporting-page/card-reasons-for-absence/absence-types-table/absence-types-table.component';
import { CardReportReasonsForAbsenceComponent } from './private/pages/reporting-page/card-reasons-for-absence/card-report-reasons-for-absence.component';
import { CardReportTimeFrequencyComponent } from './private/pages/reporting-page/card-report-time-frequency/card-report-time-frequency.component';
import { FrequencyTableComponent } from './private/pages/reporting-page/card-report-time-frequency/frequency-table/frequency-table.component';
import { ReportFilterBarComponent } from './private/pages/reporting-page/filters/report-filter-bar/report-filter-bar.component';
import { ReportFilterModalComponent } from './private/pages/reporting-page/filters/report-filter-modal/report-filter-modal.component';
import { ReportingPageComponent } from './private/pages/reporting-page/reporting-page.component';
import { ReportingSessionDetailsComponent } from './private/pages/reporting-session-details/reporting-session-details.component';
import { ReportingSubNavComponent } from './private/pages/reporting-subnav/reporting-subnav.component';
import { ReportingSupportInsightsComponent } from './private/pages/reporting-support-insights/reporting-support-insights.component';
import { ResourceViewPageComponent } from './private/pages/resource-view-page/resource-view-page.component';
import { ResourcesOnlineCoursesComponent } from './private/pages/resources-online-courses/resources-online-courses.component';
import { ResourcesSearchComponent } from './private/pages/resources-search/resources-search.component';
import { ResourcesVideoLibraryComponent } from './private/pages/resources-video-library/resources-video-library.component';
import { RostersPageComponent } from './private/pages/rosters-page/rosters-page.component';
import { SettingsComponent } from './private/pages/settings/settings.component';
import { SharedResourcesPageComponent } from './private/pages/shared-resources-page/shared-resources-page.component';
import { SingleCelebrationPageComponent } from './private/pages/single-celebration-page/single-celebration-page.component';
import { SmartCoachInfoPageComponent } from './private/pages/smart-coach/smart-coach-info-page/smart-coach-info-page.component';
import { SmartCoachIntroPageComponent } from './private/pages/smart-coach/smart-coach-intro-page/smart-coach-intro-page.component';
import { SmartCoachLogPageComponent } from './private/pages/smart-coach/smart-coach-log-page/smart-coach-log-page.component';
import { SmartCoachSummaryPageComponent } from './private/pages/smart-coach/smart-coach-summary-page/smart-coach-summary-page.component';
import { StyleLibraryComponent } from './private/pages/style-library/style-library.component';
import { TopicsCompetenciesPageComponent } from './private/pages/topics-page/topics-competencies-page/topics-competencies-page.component';
import { TopicsPageComponent } from './private/pages/topics-page/topics-page.component';
import { CatalogPageComponent } from './private/pages/training/catalog/catalog.component';
import { CopyPdSessionModalComponent } from './private/pages/training/copy-pd-session-modal/copy-pd-session-modal.component';
import { CreatePdSessionPageComponent } from './private/pages/training/create-pd-session/create-pd-session.component';
import { EditPdSessionModalComponent } from './private/pages/training/edit-pd-session-modal/edit-pd-session-modal.component';
import { SessionDetailsComponent } from './private/pages/training/session-details/session-details.component';
import { TranscriptPageComponent } from './private/pages/transcript-page/transcript-page.component';
import { BasicInputComponent } from './private/pages/user-settings/basic-input/basic-input.component';
import { UserSettingsPageComponent } from './private/pages/user-settings/user-settings.component';
import { ActionButtonsComponent } from './private/shared/components/action-buttons/action-buttons.component';
import { ActionPlanComponent } from './private/shared/components/action-plan/action-plan.component';
import { AlertComponent } from './private/shared/components/alert/alert.component';
import { AppliedFiltersComponent } from './private/shared/components/applied-filters/applied-filters.component';
import { AssignCoacheeComponent } from './private/shared/components/assign-coachee/assign-coachee.component';
import { AvatarComponent } from './private/shared/components/avatar/avatar.component';
import { BadgeEarnedModalComponent } from './private/shared/components/badge-earned-modal/badge-earned-modal.component';
import { BadgeGraphicComponent } from './private/shared/components/badge-graphic/badge-graphic.component';
import { CardBadgeProgressComponent } from './private/shared/components/badges/card-badge-progress/card-badge-progress.component';
import { CardBadgesEarnedComponent } from './private/shared/components/badges/card-badges-earned/card-badges-earned.component';
import { CardRecentBadgesComponent } from './private/shared/components/badges/card-recent-badges/card-recent-badges.component';
import { BasicProgressBarComponent } from './private/shared/components/basic-progress-bar/basic-progress-bar.component';
import { CardEvidenceForReviewItemComponent } from './private/shared/components/card-evidence-for-review-item/card-evidence-for-review-item.component';
import { CardActivityFeedComponent } from './private/shared/components/cards/card-activity-feed/card-activity-feed.component';
import { CardCelebrationsFeedComponent } from './private/shared/components/cards/card-celebrations-feed/card-celebrations-feed.component';
import { CardCoachFeedbackComponent } from './private/shared/components/cards/card-coach-feedback/card-coach-feedback.component';
import { CardCoachQuickStatsComponent } from './private/shared/components/cards/card-coach-quick-stats/card-coach-quick-stats.component';
import { CardCurrentCompetenciesComponent } from './private/shared/components/cards/card-current-competencies/card-current-competencies.component';
import { CardEvidenceForReviewComponent } from './private/shared/components/cards/card-evidence-for-review/card-evidence-for-review.component';
import { CardIdeasComponent } from './private/shared/components/cards/card-ideas/card-ideas.component';
import { CardKpiComponent } from './private/shared/components/cards/card-kpi/card-kpi.component';
import { CardLeaderGlanceComponent } from './private/shared/components/cards/card-leader-glance/card-leader-glance.component';
import { CardMysmartcoachComponent } from './private/shared/components/cards/card-mysmartcoach/card-mysmartcoach.component';
import { CardPickupComponent } from './private/shared/components/cards/card-pick-up/card-pick-up.component';
import { CardReportComponent } from './private/shared/components/cards/card-report/card-report.component';
import { CardS2SpotlightComponent } from './private/shared/components/cards/card-s2-spotlight/card-s2-spotlight.component';
import { CardSmartSuggestionsComponent } from './private/shared/components/cards/card-smart-suggestions/card-smart-suggestions.component';
import { CardUpcomingSessionsComponent } from './private/shared/components/cards/card-upcoming-sessions/card-upcoming-sessions.component';
import { CelebrationItemComponent } from './private/shared/components/celebration-item/celebration-item.component';
import { ChartGaugeComponent } from './private/shared/components/chart-gauge/chart-gauge.component';
import { CoachNotesComponent } from './private/shared/components/coach/coach-notes/coach-notes.component';
import { CoacheeListCardComponent } from './private/shared/components/coachee/coachee-list-card/coachee-list-card.component';
import { CoacheeListItemComponent } from './private/shared/components/coachee/coachee-list-item/coachee-list-item.component';
import { CoacheeNotesComponent } from './private/shared/components/coachee/coachee-notes/coachee-notes.component';
import { CoachingAttendeeComponent } from './private/shared/components/coaching-attendee/coaching-attendee.component';
import { CoachingLogCompletionComponent } from './private/shared/components/coaching-log-completion/coaching-log-completion.component';
import { CoachingLogNotesComponent } from './private/shared/components/coaching-log-notes/coaching-log-notes.component';
import { CoachingLogResourceListItemComponent } from './private/shared/components/coaching-log-resource-list-item/coaching-log-resource-list-item.component';
import { CoachingLogResourcesComponent } from './private/shared/components/coaching-log-resources/coaching-log-resources.component';
import { CoachingSessionListComponent } from './private/shared/components/coaching-session-list/coaching-session-list.component';
import { CoachingSessionSummaryComponent } from './private/shared/components/coaching-session-summary/coaching-session-summary.component';
import { CoachingShadowerComponent } from './private/shared/components/coaching-shadower/coaching-shadower.component';
import { CodoxTextareaComponent } from './private/shared/components/codox-textarea/codox-textarea.component';
import { CohortPreviewCardComponent } from './private/shared/components/cohort-preview-card/cohort-preview-card.component';
import { CohortSelectComponent } from './private/shared/components/cohort-select/cohort-select.component';
import { CohortUsersComponent } from './private/shared/components/cohort-users/cohort-users.component';
import { CompetenciesSummaryComponent } from './private/shared/components/competency/competencies-summary/competencies-summary.component';
import { CompetencyNameComponent } from './private/shared/components/competency/competency-name/competency-name.component';
import { CompetencySelectComponent } from './private/shared/components/competency/competency-select/competency-select.component';
import { BuilderLeveledCompetencyStrandLevelComponent } from './private/shared/components/competency/create-edit-competency-modal/builder-leveled-competency/builder-leveled-competency-strand/builder-level/builder-leveled-competency-strand-level.component';
import { BuilderLeveledCompetencyStrandComponent } from './private/shared/components/competency/create-edit-competency-modal/builder-leveled-competency/builder-leveled-competency-strand/builder-strand/builder-leveled-competency-strand.component';
import { BuilderLeveledCompetencyComponent } from './private/shared/components/competency/create-edit-competency-modal/builder-leveled-competency/builder-leveled-competency.component';
import { CreateEditCompetencyModalComponent } from './private/shared/components/competency/create-edit-competency-modal/create-edit-competency-modal.component';
import { GoalSliderComponent } from './private/shared/components/competency/goal-slider/goal-slider.component';
import { LeveledCompetencyModalComponent } from './private/shared/components/competency/leved-competency-modal/leveled-competency-modal.component';
import { LeveledCompetencyStrandLevelEvidenceComponent } from './private/shared/components/competency/leveled-competency-strand/level-evidence/leveled-competency-strand-level-evidence.component';
import { LeveledCompetencyStrandLevelOptionComponent } from './private/shared/components/competency/leveled-competency-strand/level-options/leveled-competency-strand-level-options.component';
import { LeveledCompetencyStrandLevelComponent } from './private/shared/components/competency/leveled-competency-strand/level/leveled-competency-strand-level.component';
import { LeveledCompetencyStrandComponent } from './private/shared/components/competency/leveled-competency-strand/strand/leveled-competency-strand.component';
import { LeveledCompetencyComponent } from './private/shared/components/competency/leveled-competency/leveled-competency.component';
import { PreviewCompetencyComponent } from './private/shared/components/competency/preview-competency-modal/preview-competency.component';
import { PreviewLeveledCompetencyStrandLevelComponent } from './private/shared/components/competency/preview-competency-modal/preview-leveled-competency/preview-leveled-competency-strand/preview-level/preview-leveled-competency-strand-level.component';
import { PreviewLeveledCompetencyStrandComponent } from './private/shared/components/competency/preview-competency-modal/preview-leveled-competency/preview-leveled-competency-strand/preview-strand/preview-leveled-competency-strand.component';
import { PreviewLeveledCompetencyComponent } from './private/shared/components/competency/preview-competency-modal/preview-leveled-competency/preview-leveled-competency.component';
import { SelectCompetenciesScreenComponent } from './private/shared/components/competency/select-competencies-screen/select-competencies-screen.component';
import { SelectCompetencyComponent } from './private/shared/components/competency/select-competency/select-competency.component';
import { ConfettiComponent } from './private/shared/components/confetti/confetti.component';
import { CreateQuickWalkModalComponent } from './private/shared/components/create-quick-walk-modal/create-quick-walk-modal.component';
import { CreateSessionModalComponent } from './private/shared/components/create-session-modal/create-session-modal.component';
import { CreateUserModalComponent } from './private/shared/components/create-user-modal/create-user-modal.component';
import { CsvUploadComponent } from './private/shared/components/csv-upload/csv-upload.component';
import { CurrentCompetenciesListItemComponent } from './private/shared/components/current-competencies-list-item/current-competencies-list-item.component';
import { CurrentCompetenciesProgressBarComponent } from './private/shared/components/current-competencies-progress-bar/current-competencies-progress-bar.component';
import { DashboardHeaderComponent } from './private/shared/components/dashboard-header/dashboard-header.component';
import { DatepickerComponent } from './private/shared/components/datepicker/datepicker.component';
import { DaterangePickerComponent } from './private/shared/components/daterange-picker/daterange-picker.component';
import { DistrictNameComponent } from './private/shared/components/district-name/district-name.component';
import { DistrictSelectComponent } from './private/shared/components/district-select/district-select.component';
import { ErrorMessageComponent } from './private/shared/components/error-message/error-message.component';
import { EvidenceCommentsComponent } from './private/shared/components/evidence-comments/evidence-comments.component';
import { EvidenceSummaryComponent } from './private/shared/components/evidence-summary/evidence-summary.component';
import { FileListComponent } from './private/shared/components/file-management/file-list/file-list.component';
import { FileUploadComponent } from './private/shared/components/file-management/file-upload/file-upload.component';
import { FormEditFieldLabelComponent } from './private/shared/components/forms/edit/base/label/form-edit-field-label.component';
import { FormEditFieldOptionsComponent } from './private/shared/components/forms/edit/base/options/form-edit-field-options.component';
import { FormEditCompetencyComponent } from './private/shared/components/forms/edit/edit-competency/edit-competency.component';
import { FormEditFieldComponent } from './private/shared/components/forms/edit/edit-field/form-edit-field.component';
import { FormEditFreetextComponent } from './private/shared/components/forms/edit/edit-freetext/edit-freetext.component';
import { FormEditMediaComponent } from './private/shared/components/forms/edit/edit-media/edit-media.component';
import { FormEditMultipleChoiceComponent } from './private/shared/components/forms/edit/edit-multiple-choice/edit-multiple-choice.component';
import { FormEditSectionComponent } from './private/shared/components/forms/edit/edit-section/edit-section.component';
import { FormEditTextComponent } from './private/shared/components/forms/edit/edit-text/edit-text.component';
import { FormSubmissionModalComponent } from './private/shared/components/forms/reports/form-submission-modal/form-submission-modal.component';
import { FormModalComponent } from './private/shared/components/forms/view/form-modal/form-modal.component';
import { FormViewCheckboxComponent } from './private/shared/components/forms/view/view-checkbox/view-checkbox.component';
import { FormViewCompetencyComponent } from './private/shared/components/forms/view/view-competency/view-competency.component';
import { FormViewFieldComponent } from './private/shared/components/forms/view/view-field/form-view-field.component';
import { ViewFormComponent } from './private/shared/components/forms/view/view-form/view-form.component';
import { FormViewMediaComponent } from './private/shared/components/forms/view/view-media/view-media.component';
import { FormViewRadioComponent } from './private/shared/components/forms/view/view-radio/view-radio.component';
import { FormViewSelectComponent } from './private/shared/components/forms/view/view-select/view-select.component';
import { FormViewTextComponent } from './private/shared/components/forms/view/view-text/view-text.component';
import { FormViewWysiwygComponent } from './private/shared/components/forms/view/view-wysiwyg/view-wysiwyg.component';
import { GeneralHeaderComponent } from './private/shared/components/general-header/general-header.component';
import { GroweLabUserCsvUploadModalComponent } from './private/shared/components/growelab-user-csv-upload-modal/growelab-user-csv-upload-modal.component';
import { GrowthIndicatorComponent } from './private/shared/components/growth-indicator/growth-indicator.component';
import { GrowthSnapshotComponent } from './private/shared/components/growth-snapshot/growth-snapshot.component';
import { HasOptionSelectComponent } from './private/shared/components/has-option-select/has-option-select.component';
import { ImplementationViewReportModalComponent } from './private/shared/components/implementation-view-report-modal/implementation-view-report-modal.component';
import { IntroCoachingPlanComponent } from './private/shared/components/intro-coaching-plan/intro-coaching-plan.component';
import { IntroCompetenciesComponent } from './private/shared/components/intro-competencies/intro-competencies.component';
import { IntroGroweProcessComponent } from './private/shared/components/intro-growe-process/intro-growe-process.component';
import { IntroOverviewComponent } from './private/shared/components/intro-overview/intro-overview.component';
import { IntroSessionSummaryComponent } from './private/shared/components/intro-session-summary/intro-session-summary.component';
import { IntroYourCoachOptionComponent } from './private/shared/components/intro-your-coach-option/intro-your-coach-option.component';
import { IntroYourCoachComponent } from './private/shared/components/intro-your-coach/intro-your-coach.component';
import { IntroYourWhyComponent } from './private/shared/components/intro-your-why/intro-your-why.component';
import { IsReviewedSelectComponent } from './private/shared/components/is-reviewed-select/is-reviewed-select.component';
import { CompetencyLevelCheckboxComponent } from './private/shared/components/leveled-resource-competencies/competency-level-checkbox/competency-level-checkbox.component';
import { LeveledResourceCompetenciesComponent } from './private/shared/components/leveled-resource-competencies/leveled-resource-competencies.component';
import { LogTypeSelectComponent } from './private/shared/components/log-type-select/log-type-select.component';
import { LogViewDetailsModalComponent } from './private/shared/components/log-view-details-modal/log-view-details-modal.component';
import { CelebrationsFilterModalComponent } from './private/shared/components/modals/celebrations-filter-modal/celebrations-filter-modal.component';
import { CoachingSessionFilterModalComponent } from './private/shared/components/modals/coaching-session-filter-modal/coaching-session-filter-modal.component';
import { CopyEvidenceModalComponent } from './private/shared/components/modals/copy-evidence-modal/copy-evidence-modal.component';
import { CreateActionItemModalComponent } from './private/shared/components/modals/create-action-item-modal/create-action-item-modal.component';
import { CreateEditCelebrationModalComponent } from './private/shared/components/modals/create-edit-celebration-modal/create-edit-celebration-modal.component';
import { CreateEditPhaseModalComponent } from './private/shared/components/modals/create-edit-phase-modal/create-edit-phase-modal.component';
import { CreateEditPlanModalComponent } from './private/shared/components/modals/create-edit-plan-modal/create-edit-plan-modal.component';
import { CreateEditTopicModalComponent } from './private/shared/components/modals/create-edit-topic-modal/create-edit-topic-modal.component';
import { DuplicateSessionModalComponent } from './private/shared/components/modals/duplicate-session-modal/duplicate-session-modal.component';
import { ImpersonateModalComponent } from './private/shared/components/modals/impersonate-modal/impersonate.modal.component';
import { ModalComponent } from './private/shared/components/modals/modal/modal.component';
import { ModifyVideoModalComponent } from './private/shared/components/modals/modify-video-modal/modify-video-modal.component';
import { NotificationsModalComponent } from './private/shared/components/modals/notifications-modal/notifications-modal.component';
import { PdFilterModalComponent } from './private/shared/components/modals/pd-filter-modal/pd-filter-modal.component';
import { TodoViewDetailsModalComponent } from './private/shared/components/modals/todo-view-details-modal/todo-view-details-modal.component';
import { TopicPageFilterModalComponent } from './private/shared/components/modals/topic-page-filter-modal/topic-page-filter-modal.component';
import { VideoModalComponent } from './private/shared/components/modals/video-modal/video-modal.component';
import { SmartCoachDialogSingleComponent } from './private/shared/components/my-smart-coach/coach-dialog-single/coach-dialog-single.component';
import { SmartCoachDialogComponent } from './private/shared/components/my-smart-coach/coach-dialog/coach-dialog.component';
import { EvidenceSelfAssessmentModalComponent } from './private/shared/components/my-smart-coach/evidence-self-assessment-modal/evidence-self-assessment-modal.component';
import { PreAssessmentModalComponent } from './private/shared/components/my-smart-coach/pre-assessment-modal/pre-assessment-modal.component';
import { SmartOptionModalComponent } from './private/shared/components/my-smart-coach/smart-option-modal/smart-option-modal.component';
import { SmartOptionSelectCardComponent } from './private/shared/components/my-smart-coach/smart-option-select-card/smart-option-select-card.component';
import { NewCoachingAttendeeComponent } from './private/shared/components/new-coaching-attendee/new-coaching-attendee.component';
import { NewCoachingShadowerComponent } from './private/shared/components/new-coaching-shadower/new-coaching-shadower.component';
import { NewPasswordComponent } from './private/shared/components/new-password/new-password.component';
import { PdListItemComponent } from './private/shared/components/pd/pd-list-item/pd-list-item.component';
import { PdSettingsCategoryComponent } from './private/shared/components/pd/pd-settings-category/pd-settings-category.component';
import { PdSettingsCreditComponent } from './private/shared/components/pd/pd-settings-credit/pd-settings-credit.component';
import { PlanProgressBarComponent } from './private/shared/components/plan-progress-bar/plan-progress-bar.component';
import { CreateDeliverableModalComponent } from './private/shared/components/plans/create-deliverable-modal/create-deliverable-modal.component';
import { CreateReportModalComponent } from './private/shared/components/plans/create-report-modal/create-report-modal.component';
import { EditActionItemModalComponent } from './private/shared/components/plans/edit-action-item-modal/edit-action-item-modal.component';
import { EditDeliverableModalComponent } from './private/shared/components/plans/edit-deliverable-modal/edit-deliverable-modal.component';
import { PhasePlanStatusCardComponent } from './private/shared/components/plans/phase-plan-status-card/phase-plan-status-card.component';
import { PhaseProgressCardComponent } from './private/shared/components/plans/phase-progress-card/phase-progress-card.component';
import { PhaseRiskDelaysCardComponent } from './private/shared/components/plans/phase-risk-delays-card/phase-risk-delays-card.component';
import { PlansCommentsComponent } from './private/shared/components/plans/plans-comments/plans-comments.component';
import { PlansStatusSelectComponent } from './private/shared/components/plans/plans-status-select/plans-status-select.component';
import { PlansSubNavComponent } from './private/shared/components/plans/plans-subnav/plans-subnav.component';
import { PlansYearlyProgressComponent } from './private/shared/components/plans/plans-yearly-progress/plans-yearly-progress.component';
import { EvidenceProgressBarComponent } from './private/shared/components/portfolio/evidence-progress-bar/evidence-progress-bar.component';
import { PortfolioCompetenciesComponent } from './private/shared/components/portfolio/portfolio-competencies/portfolio-competencies.component';
import { PortfolioLeveledCompetencyStrandLevelEvidenceComponent } from './private/shared/components/portfolio/portfolio-leveled-competency/portfolio-leveled-competency-strand/portfolio-level-evidence/portfolio-leveled-competency-strand-level-evidence.component';
import { PortfolioLeveledCompetencyStrandLevelComponent } from './private/shared/components/portfolio/portfolio-leveled-competency/portfolio-leveled-competency-strand/portfolio-level/portfolio-leveled-competency-strand-level.component';
import { PortfolioLeveledCompetencyStrandComponent } from './private/shared/components/portfolio/portfolio-leveled-competency/portfolio-leveled-competency-strand/portfolio-strand/portfolio-leveled-competency-strand.component';
import { PortfolioLeveledCompetencyComponent } from './private/shared/components/portfolio/portfolio-leveled-competency/portfolio-leveled-competency.component';
import { QuartileProgressBarComponent } from './private/shared/components/quartile-progress-bar/quartile-progress-bar.component';
import { QuartileProgressStrandOverviewComponent } from './private/shared/components/quartile-progress-strand-overview/quartile-progress-strand-overview.component';
import { QuickStatComponent } from './private/shared/components/quick-stat/quick-stat.component';
import { RecentCelebrationComponent } from './private/shared/components/recent-celebration/recent-celebration.component';
import { CalibrationTableComponent } from './private/shared/components/reports/calibration-table/calibration-table.component';
import { GrowthTableComponent } from './private/shared/components/reports/growth-table/growth-table.component';
import { HeatmapControllerComponent } from './private/shared/components/reports/heatmap-controller/heatmap-controller.component';
import { KpiControllerComponent } from './private/shared/components/reports/kpi-controller/kpi-controller.component';
import { ReportControllerComponent } from './private/shared/components/reports/report-controller/report-controller.component';
import { TableControllerComponent } from './private/shared/components/reports/table-controller/table-controller.component';
import { FolderBookmarkComponent } from './private/shared/components/resources/folder-bookmark/folder-bookmark.component';
import { FolderItemComponent } from './private/shared/components/resources/folder-item/folder-item.component';
import { FolderComponent } from './private/shared/components/resources/folder/folder.component';
import { MyResourcesListComponent } from './private/shared/components/resources/my-resources-list/my-resources-list.component';
import { OnlineCourseListComponent } from './private/shared/components/resources/online-course-list/online-course-list.component';
import { OnlineCourseComponent } from './private/shared/components/resources/online-course/online-course.component';
import { ResourceAccessRulesComponent } from './private/shared/components/resources/resource-access-rules/resource-access-rules.component';
import { ResourceContentComponent } from './private/shared/components/resources/resource-content/resource-content.component';
import { ResourceCreateModalComponent } from './private/shared/components/resources/resource-create-modal/resource-create-modal.component';
import { ResourceFilterContainerComponent } from './private/shared/components/resources/resource-filter-container/resource-filter-container.component';
import { ResourceFilterModalComponent } from './private/shared/components/resources/resource-filter-modal/resource-filter-modal.component';
import { ResourceLeveledListComponent } from './private/shared/components/resources/resource-leveled-list/resource-leveled-list.component';
import { ResourceLeveledComponent } from './private/shared/components/resources/resource-leveled/resource-leveled.component';
import { ResourceListItemComponent } from './private/shared/components/resources/resource-list-item/resource-list-item.component';
import { ResourcePreviewListComponent } from './private/shared/components/resources/resource-preview-list/resource-preview-list.component';
import { ResourcePreviewComponent } from './private/shared/components/resources/resource-preview/resource-preview.component';
import { ResourceSearchModalComponent } from './private/shared/components/resources/resource-search-modal/resource-search-modal.component';
import { ResourceTagsComponent } from './private/shared/components/resources/resource-tags/resource-tags.component';
import { ResourcesBreadcrumbComponent } from './private/shared/components/resources/resources-breadcrumb/resources-breadcrumb.component';
import { ResourcesSubnavComponent } from './private/shared/components/resources/resources-subnav/resources-subnav.component';
import { SelectResourceComponent } from './private/shared/components/resources/select-resource/select-resource.component';
import { SelectTagsComponent } from './private/shared/components/resources/select-tags/select-tags.component';
import { SharedFolderComponent } from './private/shared/components/resources/shared-folder/shared-folder.component';
import { RosterSchoolCreateModalComponent } from './private/shared/components/roster-school-create-modal/roster-school-create-modal.component';
import { RosterSchoolEditModalComponent } from './private/shared/components/roster-school-edit-modal/roster-school-edit-modal.component';
import { RosterSchoolComponent } from './private/shared/components/roster-schools/roster-schools.component';
import { RosterUsersComponent } from './private/shared/components/roster-users/roster-users.component';
import { SafehtmlComponent } from './private/shared/components/safehtml/safehtml.component';
import { SchoolNameComponent } from './private/shared/components/school-name/school-name.component';
import { SchoolSelectLabelComponent } from './private/shared/components/school-select/label/school-select-label.component';
import { SchoolSelectComponent } from './private/shared/components/school-select/school-select.component';
import { SessionTimeoutModalComponent } from './private/shared/components/session-timeout-modal/session-timeout-modal.component';
import { SessionTypeModalComponent } from './private/shared/components/session-type-modal/session-type-modal.component';
import { SettingsNavComponent } from './private/shared/components/settings/settings-nav/settings-nav.component';
import { StrandSelectComponent } from './private/shared/components/strand-select/strand-select.component';
import { StudentProjectedGrowthComponent } from './private/shared/components/student-projected-growth/student-projected-growth.component';
import { TestingOffcanvasPanelComponent } from './private/shared/components/testing-offcanvas-panel/testing-offcanvas-panel.component';
import { CardTodosComponent } from './private/shared/components/todos/card-todos/card-todos.component';
import { TodoAssigneeComponent } from './private/shared/components/todos/todo-assignee/todo-assignee.component';
import { TodoItemComponent } from './private/shared/components/todos/todo-item/todo-item.component';
import { TopicSelectComponent } from './private/shared/components/topic-select/topic-select.component';
import { UncheckConfirmModalComponent } from './private/shared/components/uncheck-confirm-modal/uncheck-confirm-modal.component';
import { CoachSelectComponent } from './private/shared/components/user-select/coach-select/coach-select.component';
import { UserSelectLabelComponent } from './private/shared/components/user-select/label/user-select-label.component';
import { UserSelectComponent } from './private/shared/components/user-select/user-select.component';
import { EditUserModalComponent } from './private/shared/components/user/edit-user-modal/edit-user-modal.component';
import { UsersNameComponent } from './private/shared/components/users-name/users-name.component';
import { VideoCardComponent } from './private/shared/components/video-card/video-card.component';
import { VideoMenuActionsComponent } from './private/shared/components/video-menu-actions/video-menu-actions.component';
import { VideoPlayerComponent } from './private/shared/components/video-player/video-player.component';
import { WysiwygEditorComponent } from './private/shared/components/wysiwyg-editor/wysiwyg-editor.component';
import { YearSelectComponent } from './private/shared/components/year-select/year-select.component';
import { AutosaveDirective } from './private/shared/directives/autosave.directive';
import { SortableHeader } from './private/shared/directives/sortable-header.directive';
import { ActivityFeedService } from './private/shared/services/activity-feed/activity-feed.service';
import { CoachingLogAttendancePusherService } from './private/shared/services/coaching-log/coaching-log-attendance-pusher.service';
import { CoachingLogPusherService } from './private/shared/services/coaching-log/coaching-log-pusher.service';
import { CoachingLogResourceService } from './private/shared/services/coaching-log/coaching-log-resource.service';
import { CodoxService } from './private/shared/services/codox/codox.service';
import { DisplayService } from './private/shared/services/display/display.service';
import { DistrictSearchService } from './private/shared/services/district-search/district-search.service';
import { EvidenceFileService } from './private/shared/services/file/evidence.file.service';
import { FileService } from './private/shared/services/file/file.service';
import { LogFileService } from './private/shared/services/file/log.file.service';
import { FormsReportService } from './private/shared/services/forms/forms-report.service';
import { FormsSaveService } from './private/shared/services/forms/forms-save.service';
import { FormsService } from './private/shared/services/forms/forms.service';
import { LogTypeSearchService } from './private/shared/services/log-type-search/log-type-search.service';
import { PusherService } from './private/shared/services/pusher/pusher.service';
import { UserPusherService } from './private/shared/services/pusher/user.pusher.service';
import { SchoolSearchService } from './private/shared/services/school-search/school-search.service';
import { CoachDialogService } from './private/shared/services/smart-coach/coach-dialog/coach-dialog.service';
import { SmartCoachSessionService } from './private/shared/services/smart-coach/smart-coach-session';
import { SmartSessionService } from './private/shared/services/smart-coach/smart-session.service';
import { ThoughtsService } from './private/shared/services/thoughts/thoughts.service';
import { TodoService } from './private/shared/services/todo/todo.service';
import { UnleashService } from './private/shared/services/unleash/unleash.service';
import { CoachingLogState } from './private/shared/state/coaching-log/coaching-log.state';
import { ImplementationPlanState } from './private/shared/state/implementation-plan/implementation-plan.state';
import { ReportingState } from './private/shared/state/reporting/reporting.state';
import { FooterComponent } from './public/components/footer/footer.component';
import { LoaderComponent } from './public/components/loader/loader.component';
import { GuestLayoutComponent } from './public/layouts/guest-layout/guest-layout.component';
import { PageNotFoundComponent } from './public/pages/404/404.component';
import { ChangePasswordRequestComponent } from './public/pages/change-password-request/change-password-request.component';
import { ChangePasswordComponent } from './public/pages/change-password/change-password.component';
import { CleverLoginComponent } from './public/pages/clever-login/clever-login.component';
import { DataSharingComponent } from './public/pages/data-sharing/data-sharing.component';
import { GoogleAdminCalendarComponent } from './public/pages/google-admin-calendar/google-admin-calendar.component';
import { LoginComponent } from './public/pages/login/login.component';
import { PlansPublicPageComponent } from './public/pages/plans-public-page/plans-public-page.component';
import { PrivacyPolicyComponent } from './public/pages/privacy-policy/privacy-policy.component';
import { TermsComponent } from './public/pages/terms/terms.component';
import { WelcomeComponent } from './public/pages/welcome/welcome.component';

@NgModule({
  declarations: [
    SidebarComponent,
    DashboardHeaderComponent,
    AppComponent,
    StudentProjectedGrowthComponent,
    AvatarComponent,
    CoacheeListCardComponent,
    CoacheeListItemComponent,
    GrowelabLayoutComponent,
    GuestLayoutComponent,
    FilterTableSearch,
    SortableHeader,
    LoginComponent,
    PageNotFoundComponent,
    DashboardComponent,
    CoachDashboardComponent,
    LeaderDashboardComponent,
    LearnerDashboardComponent,
    SettingsComponent,
    PermissionDeniedPageComponent,
    CoacheeListPageComponent,
    StyleLibraryComponent,
    AvatarComponent,
    CoacheeListCardComponent,
    CoacheeListItemComponent,
    LoginComponent,
    CoachingSessionFilterModalComponent,
    GrowelabLayoutComponent,
    GuestLayoutComponent,
    FilterTableSearch,
    CoachingLogPageComponent,
    CoacheeNotesComponent,
    SortableHeader,
    GoalSliderComponent,
    CardUpcomingSessionsComponent,
    ShowDateTimezonePipe,
    FormatTimePipe,
    FormatDatePipe,
    FormatDateMMDDYYPipe,
    FormatDistanceToNowPipe,
    FormatDateTimePipe,
    FullNameProfilePipe,
    FormatNgbDatePipe,
    FormatActivityFeedDateTime,
    TimezoneLinkBackPipe,
    ArraySortPipe,
    LeveledCompetencyModalComponent,
    LeveledCompetencyComponent,
    LeveledCompetencyStrandComponent,
    LeveledCompetencyStrandLevelComponent,
    RecentCelebrationComponent,
    LeveledCompetencyStrandLevelOptionComponent,
    LeveledCompetencyStrandLevelEvidenceComponent,
    GrowthIndicatorComponent,
    GrowthSnapshotComponent,
    ConfettiComponent,
    CardS2SpotlightComponent,
    CardSmartSuggestionsComponent,
    CardPickupComponent,
    CardIdeasComponent,
    BadgeEarnedModalComponent,
    CardEvidenceForReviewComponent,
    BadgeEarnedModalComponent,
    CardBadgeProgressComponent,
    CardBadgesEarnedComponent,
    LoaderComponent,
    CardTodosComponent,
    CoachingSessionsPageComponent,
    CoachingSessionListComponent,
    CoachingLogNotesComponent,
    TodoItemComponent,
    DatepickerComponent,
    CodoxTextareaComponent,
    CoachingSessionsPageComponent,
    CoachingSessionListComponent,
    WysiwygEditorComponent,
    ModalComponent,
    UserSelectComponent,
    UserSelectLabelComponent,
    SelectResourceComponent,
    ResourcePreviewComponent,
    ResourcePreviewListComponent,
    ResourcesSubnavComponent,
    AutosaveDirective,
    ResourcesSearchComponent,
    ResourceListItemComponent,
    UsersNameComponent,
    SelectCompetenciesScreenComponent,
    SelectCompetencyComponent,
    AddDistrictComponent,
    EditDistrictComponent,
    CoachingAttendeeComponent,
    DaterangePickerComponent,
    SchoolSelectComponent,
    SchoolSelectLabelComponent,
    LogTypeSelectComponent,
    AppliedFiltersComponent,
    DistrictNameComponent,
    SchoolNameComponent,
    DistrictSelectComponent,
    FormAnswerSelectComponent,
    ResourceViewPageComponent,
    FileUploadComponent,
    FileListComponent,
    ResourcesVideoLibraryComponent,
    OnlineCourseListComponent,
    OnlineCourseComponent,
    ResourcesOnlineCoursesComponent,
    ResourceFilterContainerComponent,
    ResourceFilterContainerComponent,
    ResourceFilterModalComponent,
    SelectTagsComponent,
    FolderBookmarkComponent,
    VideoCardComponent,
    VideoPlayerComponent,
    VideoMenuActionsComponent,
    SafehtmlComponent,
    CoachingShadowerComponent,
    CoachingLogInfoPageComponent,
    ResourceAccessRulesComponent,
    ResourceTagsComponent,
    ResourceContentComponent,
    CoachingSessionSummaryComponent,
    ObservationInfoPageComponent,
    ObservationFormPageComponent,
    ObservationSummaryPageComponent,
    CreateSessionModalComponent,
    NewCoachingAttendeeComponent,
    NewCoachingShadowerComponent,
    ResourceCreateModalComponent,
    CoacheeEvidencePageComponent,
    CompetenciesSummaryComponent,
    QuartileProgressBarComponent,
    QuartileProgressStrandOverviewComponent,
    MyResourcesPageComponent,
    FolderComponent,
    CompetencySelectComponent,
    ImpersonateModalComponent,
    TruncatePhrasePipe,
    CompetencyNameComponent,
    ResourceLeveledListComponent,
    ResourceLeveledComponent,
    FolderItemComponent,
    MyResourcesListComponent,
    EvidenceSummaryComponent,
    CardEvidenceForReviewComponent,
    LeveledResourceCompetenciesComponent,
    CompetencyLevelCheckboxComponent,
    FormsListPageComponent,
    CreateFormPageComponent,
    EditFormPageComponent,
    FormEditFieldComponent,
    FormEditCompetencyComponent,
    FormEditSectionComponent,
    FormEditFreetextComponent,
    FormEditTextComponent,
    FormEditMultipleChoiceComponent,
    FormEditFieldLabelComponent,
    FormEditFieldOptionsComponent,
    FormEditMediaComponent,
    ViewFormComponent,
    FormModalComponent,
    FormViewFieldComponent,
    FormViewCheckboxComponent,
    FormViewSelectComponent,
    FormViewTextComponent,
    FormViewWysiwygComponent,
    FormViewRadioComponent,
    FormViewCompetencyComponent,
    FormViewMediaComponent,
    SettingsNavComponent,
    UserSettingsPageComponent,
    BasicInputComponent,
    EvidenceCommentsComponent,
    CardEvidenceForReviewItemComponent,
    ChangePasswordRequestComponent,
    CohortsPageComponent,
    CohortPreviewCardComponent,
    ReportControllerComponent,
    TableControllerComponent,
    KpiControllerComponent,
    BadgeGraphicComponent,
    ReportingPageComponent,
    ReportingSubNavComponent,
    ReportingSupportInsightsComponent,
    ReportingBadgeProgressComponent,
    ReportingObservationsComponent,
    ReportingSessionDetailsComponent,
    ReportingDownloadComponent,
    CardKpiComponent,
    CardReportComponent,
    CardCurrentCompetenciesComponent,
    CurrentCompetenciesListItemComponent,
    CurrentCompetenciesProgressBarComponent,
    RostersPageComponent,
    RosterSchoolComponent,
    CardRecentBadgesComponent,
    CardLeaderGlanceComponent,
    CreateUserModalComponent,
    RosterUsersComponent,
    WelcomeComponent,
    PrivacyPolicyComponent,
    TermsComponent,
    DataSharingComponent,
    FooterComponent,
    StripHtmlPipe,
    CsvUploadComponent,
    CardCelebrationsFeedComponent,
    CreateEditCelebrationModalComponent,
    AssignCoacheeComponent,
    CleverLoginComponent,
    YearSelectComponent,
    GeneralLogPageComponent,
    GeneralLogInfoPageComponent,
    ActionPlanComponent,
    CohortUsersComponent,
    VideoModalComponent,
    ModifyVideoModalComponent,
    SmartCoachIntroPageComponent,
    IntroYourWhyComponent,
    IntroOverviewComponent,
    IntroGroweProcessComponent,
    IntroCompetenciesComponent,
    IntroYourCoachComponent,
    IntroCoachingPlanComponent,
    IntroSessionSummaryComponent,
    GeneralHeaderComponent,
    CalendarPageComponent,
    AllCelebrationsPageComponent,
    SingleCelebrationPageComponent,
    CelebrationItemComponent,
    LogViewDetailsModalComponent,
    TodoViewDetailsModalComponent,
    CelebrationsFilterModalComponent,
    SmartCoachDialogComponent,
    SmartCoachDialogSingleComponent,
    IntroYourCoachOptionComponent,
    CardMysmartcoachComponent,
    SmartCoachInfoPageComponent,
    TodoAssigneeComponent,
    ReportFilterModalComponent,
    ReportFilterBarComponent,
    CohortSelectComponent,
    CoachSelectComponent,
    TopicSelectComponent,
    SmartCoachLogPageComponent,
    HeatmapControllerComponent,
    SmartCoachSummaryPageComponent,
    SmartOptionModalComponent,
    SmartOptionSelectCardComponent,
    CardReportTimeFrequencyComponent,
    CardReportReasonsForAbsenceComponent,
    FrequencyTableComponent,
    AbsenceTypesTableComponent,
    ModalComponent,
    FormSubmissionModalComponent,
    PreAssessmentModalComponent,
    EvidenceSelfAssessmentModalComponent,
    PortfolioViewPageComponent,
    EvidenceProgressBarComponent,
    PortfolioCompetenciesComponent,
    QuickStatComponent,
    ErrorMessageComponent,
    PortfolioLeveledCompetencyComponent,
    PortfolioLeveledCompetencyStrandComponent,
    PortfolioLeveledCompetencyStrandLevelComponent,
    PortfolioLeveledCompetencyStrandLevelEvidenceComponent,
    SessionTimeoutModalComponent,
    ReportingEvidenceComponent,
    NewPasswordComponent,
    ChangePasswordComponent,
    SessionTypeModalComponent,
    HasOptionSelectComponent,
    IsReviewedSelectComponent,
    ConfigurationPageComponent,
    UncheckConfirmModalComponent,
    ResourceSearchModalComponent,
    NotificationsModalComponent,
    PortfolioBadgesPageComponent,
    GrowthTableComponent,
    CoachingLogCompletionComponent,
    AlertComponent,
    CalibrationTableComponent,
    CardActivityFeedComponent,
    EditUserModalComponent,
    CopyEvidenceModalComponent,
    StrandSelectComponent,
    SharedFolderComponent,
    SharedResourcesPageComponent,
    ResourcesBreadcrumbComponent,
    RosterSchoolCreateModalComponent,
    RosterSchoolEditModalComponent,
    GroweLabUserCsvUploadModalComponent,
    PreviewCompetencyComponent,
    PreviewLeveledCompetencyComponent,
    PreviewLeveledCompetencyStrandComponent,
    PreviewLeveledCompetencyStrandLevelComponent,
    TopicsPageComponent,
    TopicPageFilterModalComponent,
    TopicsCompetenciesPageComponent,
    CreateEditTopicModalComponent,
    CoachingCompetenciesPageComponent,
    CreateEditCompetencyModalComponent,
    BuilderLeveledCompetencyComponent,
    BuilderLeveledCompetencyStrandComponent,
    BuilderLeveledCompetencyStrandLevelComponent,
    CreateEditPlanModalComponent,
    CreateEditPhaseModalComponent,
    PlansPageComponent,
    PlanProgressBarComponent,
    PlansStatusSelectComponent,
    ImplementationPlanPageComponent,
    ImplementationPlanDetailsPageComponent,
    CardCoachQuickStatsComponent,
    EditDeliverableModalComponent,
    EditActionItemModalComponent,
    PhasePlanStatusCardComponent,
    PhaseRiskDelaysCardComponent,
    PhaseProgressCardComponent,
    ConvertToLowercasePipe,
    CoachListPageComponent,
    BasicProgressBarComponent,
    CoachNotesComponent,
    CreateActionItemModalComponent,
    CreateDeliverableModalComponent,
    PlansCommentsComponent,
    GoogleAdminCalendarComponent,
    CreateQuickWalkModalComponent,
    DuplicateSessionModalComponent,
    PlansYearlyProgressComponent,
    PlansPublicPageComponent,
    ImplementationPlanSharingPageComponent,
    ImplementationStatusReportsPageComponent,
    ImplementationViewReportModalComponent,
    PlansSubNavComponent,
    CreateReportModalComponent,
    ActionButtonsComponent,
    ChartGaugeComponent,
    CoachFeedbackPageComponent,
    CardCoachFeedbackComponent,
    CoachingLogResourcesComponent,
    CoachingLogResourceListItemComponent,
    CheckDeliverablesWithinYearPipe,
    CheckActionItemWithinYearPipe,
    CatalogPageComponent,
    PdListItemComponent,
    CreatePdSessionPageComponent,
    CopyPdSessionModalComponent,
    PdFilterModalComponent,
    FormatDateOrdinalPipe,
    SessionDetailsComponent,
    TestingOffcanvasPanelComponent,
    PdAdminPageComponent,
    PdSettingsCategoryComponent,
    PdSettingsCreditComponent,
    TranscriptPageComponent,
    EditPdSessionModalComponent,
  ],
  imports: [
    NgxsModule.forRoot([
      UserState,
      CoachingLogState,
      ReportingState,
      ImplementationPlanState,
    ]),
    IntercomModule.forRoot({
      appId: environment.intercomId,
      updateOnRouterChange: true,
    }),
    BrowserModule,
    AppRoutingModule,
    RouterModule,
    HttpClientModule,
    FormsModule,
    NgbModule,
    NgbCollapseModule,
    NgbProgressbar,
    ReactiveFormsModule,
    NgbPopoverModule,
    DragDropModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxParticlesModule,
    NgSelectModule,
    BrowserAnimationsModule,
    FullCalendarModule,
  ],
  providers: [
    ChatBotService,
    DecimalPipe,
    CodoxService,
    TodoService,
    EnvironmentService,
    DisplayService,
    HttpClientModule,
    PusherService,
    UserPusherService,
    ThoughtsService,
    CoachingLogPusherService,
    CoachingLogResourceService,
    CoachingLogAttendancePusherService,
    SchoolSearchService,
    DistrictSearchService,
    LogTypeSearchService,
    FileService,
    LogFileService,
    CoachDialogService,
    EvidenceFileService,
    FormsService,
    FormsReportService,
    FormsSaveService,
    SmartCoachSessionService,
    UnleashService,
    SmartSessionService,
    ActivityFeedService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiAuthInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService,
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
