import { Clipboard } from '@angular/cdk/clipboard';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { DateTime } from 'luxon';
import { EnvironmentService } from 'src/app/common/services/environment/environment.service';
import { UserLiteDTO } from 'src/app/common/state/user/user.dto';
import { User } from 'src/app/common/state/user/user.model';
import { UserState } from 'src/app/common/state/user/user.state';
import { booleanFromBooleanNumber } from 'src/app/common/utilities/enums/boolean-number.enum';
import {
  PdAttendee,
  PdContext,
  PdCreditsReceived,
  PdEarnedItem,
  PdFormUser,
  PdSession,
  PdSessionAPIResponse,
  PdSessionFinishedPayload,
  PdSessionFinishedUserPayload,
  PdSessionFinishedUserPayloadFromPdFormUser,
  PdViewingUser,
} from 'src/app/private/shared/dtos/pd.dto';
import {
  AlertService,
  AlertType,
} from 'src/app/private/shared/services/alert/alert.service';
import { PdService } from 'src/app/private/shared/services/pd/pd.service';

@Component({
  selector: 'app-session-details',
  templateUrl: './session-details.component.html',
  styleUrl: './session-details.component.scss',
})
export class SessionDetailsComponent implements OnInit, OnDestroy {
  sessionId: number;

  selAttendeeId: number;

  selAttendee: PdAttendee | undefined;

  session: PdSession;

  context: PdContext;

  user: User;

  isRegistered: boolean;

  isFacilitator: boolean;

  sessionOverdue: boolean;

  isPresent: boolean;

  duration?: string;

  backgroundImage: string;

  public API_URL = EnvironmentService.apiUrl();

  earnedCredits: PdEarnedItem[];

  // ATTENDANCE
  attendanceCode: string;

  attendanceLoading: boolean;

  errorMessage: string | undefined;

  // NOTES
  initialNotesContent: string;

  isSavingNotes = false;

  creditsReceived: PdCreditsReceived;

  viewingUser: PdViewingUser;

  // ATTENDEE MGMT FORM
  usersCreditForm: FormGroup;

  usersCreditFormSubmitted = false;

  userPayload: PdSessionFinishedUserPayload[];

  formSubmitting = false;

  constructor(
    private pdService: PdService,
    private route: ActivatedRoute,
    private store: Store,
    private clipboard: Clipboard,
    private fb: FormBuilder,
    private alertService: AlertService
  ) {
    this.user = this.store.selectSnapshot(UserState.getUser) as User;
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.sessionId = parseInt(params['sessionId']);

      this.route.queryParams.subscribe((queryParams) => {
        if (queryParams['user_id']) {
          this.selAttendeeId = parseInt(queryParams['user_id']);
        }
      });

      this.getSession(this.sessionId);
    });
  }

  private buildForm() {
    this.usersCreditForm = this.fb.group({
      users: this.fb.array([]),
    });

    if (this.session.attendees && this.session.attendees.length > 0) {
      const usersArray = this.usersCreditForm.controls['users'] as FormArray;
      this.session.attendees?.forEach((attendee) => {
        const newUserGroup = new FormGroup({
          user_id: new FormControl(attendee.user_id, []),
          user_profile: new FormControl(attendee.user.profile),
          is_present: new FormControl(
            booleanFromBooleanNumber(attendee.is_present),
            []
          ),
          credits: this.fb.array([]),
        });

        if (this.session.credits && this.session.credits.length > 0) {
          const creditsArray = newUserGroup.controls.credits as FormArray;
          this.session.credits.forEach((credit) => {
            const newCreditGroupType = new FormGroup({
              credit_type: new FormControl(credit.credit_type, [
                Validators.required,
              ]),
              credit_value: new FormControl(credit.credit_amount, [
                Validators.required,
              ]),
              credit_max_value: new FormControl(credit.credit_amount, []),
            });
            creditsArray.push(newCreditGroupType);
          });
        }

        usersArray.push(newUserGroup);
      });
    }
  }

  get users() {
    return this.usersCreditForm.get('users') as FormArray;
  }

  getCreditsFor(index: number) {
    return (<FormArray>(
      (<FormArray>this.usersCreditForm.get('users')).controls[index].get(
        'credits'
      )
    )).controls;
  }

  getCompletedSession(sessionId: number, selUserId: number) {
    this.pdService
      .getCompletedUserSession(sessionId, selUserId)
      .subscribe((resp: PdSessionAPIResponse) => {
        if (this.session?.is_completed) {
          this.viewingUser = resp.context.viewing_user;
          if (this.session.attendees) {
            this.selAttendee = this.session.attendees?.find(
              (user) => user.user_id === this.selAttendeeId
            );
          }
          this.creditsReceived = resp.context.credits_received;
        }
      });
  }

  getSession(sessionId: number) {
    this.pdService
      .getSession(sessionId)
      .subscribe((resp: PdSessionAPIResponse) => {
        if (resp.item) {
          this.session = resp.item;
          this.context = resp.context;
          this.initialNotesContent = this.session.notes;

          if (this.session.is_completed) {
            document.body.classList.add('session_post');

            this.getEarnedCredits(this.session.id);

            if (this.selAttendeeId) {
              this.getCompletedSession(this.sessionId, this.selAttendeeId);
            } else {
              this.viewingUser = resp.context.viewing_user;
              this.creditsReceived = this.context.credits_received;
            }
          } else {
            document.body.classList.add('session_pre');
          }
          this.calculateSessionDuration();

          this.checkifPastSessionDate();

          if (this.session.image) {
            this.backgroundImage = `url(${this.API_URL}${this.session.image})`;
          } else {
            this.backgroundImage = 'url(/assets/pd_list_item_default.png)';
          }

          if (this.user) {
            // IS USER A FACILITATOR?
            if (
              this.session.facilitators &&
              this.session.facilitators.length > 0
            ) {
              this.isFacilitator = this.session.facilitators.some(
                (facilitator) =>
                  facilitator.facilitator_user_id === this.user.id
              );

              if (this.isFacilitator) {
                this.buildForm();
              }

              if (this.session.attendees && this.session.attendees.length > 0) {
                // IF NOT A FACILITATOR, CHECK ATTENDEE CREDENTIALS
                if (!this.isFacilitator) {
                  // IS USER REGISTERED?
                  this.isRegistered = this.session.attendees.some(
                    (attendee) => attendee.user_id === this.user?.id
                  );

                  // IS USER PRESENT?
                  if (this.isRegistered) {
                    const selUser = this.session.attendees.find(
                      (attendee) => attendee.user_id === this.user?.id
                    );
                    this.isPresent = !!selUser?.is_present;
                  }
                }
              }
            }
          }
        }
      });
  }

  checkifPastSessionDate() {
    const todaysDate = new Date();
    // allows users to register for full day of event
    const sessionDate = new Date(this.session.end_datetime * 1000);
    sessionDate.setHours(25, 0, 0);
    this.sessionOverdue = todaysDate > sessionDate;
  }

  calculateSessionDuration() {
    const diff = DateTime.fromSeconds(this.session.end_datetime)
      .diff(DateTime.fromSeconds(this.session.start_datetime), 'minutes')
      .toObject();

    const stringMin = diff.minutes?.toFixed(2);
    if (stringMin && diff) {
      const minNumber = parseInt(stringMin);
      const hours = Math.floor(minNumber / 60);

      const minutes = minNumber % 60;
      this.duration = `${hours === 0 ? '' : `${hours}h`}${
        minutes === 0 ? '' : ` ${minutes}m`
      }`;
    }
  }

  unregisterUser(sessionId: number, userId: number) {
    this.pdService.unregisterSession(sessionId, userId).subscribe(() => {
      this.isRegistered = false;
    });
  }

  // BEGIN ATTENDEE ONLY FEATURES
  selfRegister(sessionId: number) {
    this.pdService.registerSession(sessionId).subscribe(() => {
      this.isRegistered = true;
    });
  }

  attCodeSubmit(sessionId: number = this.session.id) {
    this.attendanceLoading = true;
    this.pdService.attendSession(sessionId, this.attendanceCode).subscribe({
      error: (err) => {
        this.attendanceLoading = false;
        this.errorMessage = err.error.message;
        // RESET ERROR MESSAGE
        setTimeout(() => {
          this.errorMessage = undefined;
        }, 4000);
      },
      complete: () => {
        this.isPresent = true;
        this.attendanceLoading = false;
      },
    });
  }
  // END ATTENDEE ONLY FEATURES

  // BEGIN FACILITATOR ONLY FEATURES
  copyCode(code: string) {
    this.clipboard.copy(code);
    this.alertService.showAlert('Attendence Code copied to clipboard');
  }

  updateSession(session: PdSession) {
    this.getSession(session.id);
  }

  updateNotes(newNotes: string) {
    this.isSavingNotes = true;
    if (this.session.notes !== newNotes) {
      this.pdService
        .updateSession(this.session.id, { notes: newNotes })
        .subscribe((resp) => {
          if (resp) {
            this.isSavingNotes = false;
          }
        });
    }
  }

  // ATTENDEE MANAGEMENT
  registerUser(newAttendee: UserLiteDTO | null) {
    if (newAttendee) {
      this.pdService
        .registerSessionForAnotherUser(this.sessionId, newAttendee.id)
        .subscribe({
          error: (error) => {
            this.alertService.showAlert(
              error.error.message,
              undefined,
              AlertType.DANGER
            );
          },
          next: (resp: PdAttendee) => {
            this.session.attendees?.push(resp);
            this.addUsertoFormGroup(resp);
          },
        });
    }
  }

  addUsertoFormGroup(attendee: PdAttendee) {
    const usersArray = this.usersCreditForm.controls['users'] as FormArray;

    const newUserGroup = new FormGroup({
      user_id: new FormControl(attendee.user_id, []),
      user_profile: new FormControl(attendee.user.profile),
      is_present: new FormControl(attendee.is_present, []),
      credits: this.fb.array([]),
    });

    const creditsArray = newUserGroup.controls.credits as FormArray;

    if (this.session.credits) {
      this.session.credits.forEach((credit) => {
        const newCreditGroupType = new FormGroup({
          credit_type: new FormControl(credit.credit_type, [
            Validators.required,
          ]),
          credit_value: new FormControl(credit.credit_amount, [
            Validators.required,
          ]),
        });
        creditsArray.push(newCreditGroupType);
      });
    }

    usersArray.push(newUserGroup);
  }

  onPresentCheckboxChange($event: Event, attendeeId: number): void {
    const target = $event.target as HTMLInputElement | null;
    if (target) {
      const updatedValue = target.checked ? 1 : 0;
      this.pdService
        .setAttendeePresent(this.session.id, attendeeId, updatedValue)
        .subscribe();
    }
  }

  completeSession() {
    if (this.usersCreditForm.invalid) {
      return;
    }

    this.formSubmitting = true;

    let sessionFormUsers: PdFormUser[] = [];

    this.usersCreditForm.value.users.forEach((user: PdFormUser) => {
      sessionFormUsers.push(user);
    });
    // REMOVE USER IF NOT PRESENT
    sessionFormUsers = sessionFormUsers.filter(
      (user) => user.is_present === true
    );

    // REMOVE UNNECESSARY VALULES
    sessionFormUsers = sessionFormUsers.map((user) =>
      PdSessionFinishedUserPayloadFromPdFormUser(user)
    );

    // CREATE FINAL PAYLOAD
    const sessionCompletePayload: PdSessionFinishedPayload = {
      users: sessionFormUsers,
    };

    this.pdService
      .completeSession(this.sessionId, sessionCompletePayload)
      .subscribe({
        error: () => {
          this.formSubmitting = false;
          this.alertService.showAlert('Session could not be submitted');
        },
        next: () => {
          this.getSession(this.sessionId);
        },
      });
  }

  getEarnedCredits(sessionId: number) {
    this.pdService.sessionEarnedCredits(sessionId).subscribe((resp) => {
      if (resp.items) {
        this.earnedCredits = resp.items;
      }
    });
  }
  // END FACILITATOR ONLY FEATURES

  // eslint-disable-next-line class-methods-use-this
  ngOnDestroy() {
    // REMOVE BACKGROUND CLASSES!
    document.body.classList.remove('session_pre');
    document.body.classList.remove('session_post');
  }
}
