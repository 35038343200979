<!-- <ng-particles
  id="tsparticles"
  [options]="confettiOptions"
  [particlesInit]="particlesInit"
  *ngIf="particlesVisible"></ng-particles> -->

<ngx-particles
  id="tsparticles"
  [options]="confettiOptions"
  [particlesInit]="particlesInit"
  (particlesLoaded)="particlesLoaded($event)"
  *ngIf="particlesVisible"></ngx-particles>
