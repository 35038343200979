import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { User } from 'src/app/common/state/user/user.model';
import { PaginationDTO } from 'src/app/common/types/responses/responses-template';
import { BooleanNumber } from 'src/app/common/utilities/enums/boolean-number.enum';
import { FilterList } from 'src/app/private/shared/components/applied-filters/applied-filters.dto';
import { ModalComponent } from 'src/app/private/shared/components/modals/modal/modal.component';
import { PdFilterModalComponent } from 'src/app/private/shared/components/modals/pd-filter-modal/pd-filter-modal.component';
import {
  PdListFilterDTO,
  PdSession,
  PdSettings,
} from 'src/app/private/shared/dtos/pd.dto';
import { PdService } from 'src/app/private/shared/services/pd/pd.service';

@Component({
  selector: 'app-copy-pd-session-modal',
  templateUrl: './copy-pd-session-modal.component.html',
  styleUrl: './copy-pd-session-modal.component.scss',
})
export class CopyPdSessionModalComponent implements OnInit {
  @ViewChild('modal') modal: ModalComponent;

  @ViewChild('pdFilterModal') pdFilterModal: PdFilterModalComponent;

  @Output() readonly sessionCopied = new EventEmitter<PdSession>();

  user: User;

  sessions: PdSession[];

  currentFilters: PdListFilterDTO = {
    from_date: null,
    to_date: null,
    keyword: '',
    facilitators: [],
    types: [],
    virtual: BooleanNumber.FALSE,
    onsite: BooleanNumber.FALSE,
    categories: [],
    per_page: 5,
    page: 1,
    include_completed: BooleanNumber.FALSE,
    competencyList: [],
  };

  settings: PdSettings;

  listMeta: PaginationDTO;

  constructor(private store: Store, private pdService: PdService) {
    this.user = this.store.selectSnapshot((state) => state.user.user) as User;
  }

  ngOnInit() {
    this.getSessions();
    this.getSettings();
  }

  closeModal() {
    this.modal.close();
  }

  getSessions() {
    this.pdService.getSessions(this.currentFilters).subscribe((resp) => {
      if (resp.items) {
        this.sessions = resp.items;
        this.listMeta = resp._meta;
      }
    });
  }

  getSettings() {
    this.pdService.getAdminSettings(this.user.district?.id).subscribe((res) => {
      if (res.item) {
        this.settings = res.item;
      }
    });
  }

  search(event: Event) {
    const term = (<HTMLInputElement>event.target).value;
    this.currentFilters.keyword = term;
    this.getSessions();
  }

  removeFilter(keyName: string) {
    /* eslint-disable-next-line default-case */
    switch (keyName) {
      case 'date':
        this.currentFilters.from_date = null;
        this.currentFilters.to_date = null;
        break;
      case 'keyword':
        this.currentFilters.keyword = '';
        break;
      case 'virtual':
        this.currentFilters.virtual = BooleanNumber.FALSE;
        break;
      case 'completed':
        this.currentFilters.include_completed = BooleanNumber.FALSE;
        break;
    }
    this.getSessions();
  }

  removeFilterFromList(filterList: FilterList) {
    /* eslint-disable-next-line default-case */
    switch (filterList.keyName) {
      case 'facilitators':
        if (this.currentFilters.facilitators) {
          this.currentFilters.facilitators =
            this.currentFilters.facilitators.filter(
              (item) => item !== filterList.value
            );
        }
        break;
      case 'types':
        if (this.currentFilters.types) {
          this.currentFilters.types = this.currentFilters.types.filter(
            (item) => item !== filterList.value
          );
        }
        break;
      case 'categories':
        if (this.currentFilters.categories) {
          this.currentFilters.categories =
            this.currentFilters.categories.filter(
              (item) => item !== filterList.value
            );
        }
        break;
    }
    this.getSessions();
  }

  applyFilters(filters: PdListFilterDTO) {
    this.currentFilters = filters;
    this.getSessions();
  }

  copySession(session: PdSession) {
    this.sessionCopied.next(session);
  }
}
