import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgParticlesService } from '@tsparticles/angular';
import { Container, Engine, tsParticles } from '@tsparticles/engine';
import { loadConfettiPreset } from '@tsparticles/preset-confetti';
import { Subscription } from 'rxjs';
import { loadFull } from 'tsparticles';
import { ConfettiService } from '../../services/confetti/confetti.service';
import {
  confettiOptionsBottom,
  confettiOptionsDefault,
  confettiOptionsSides,
  confettiOptionsTop,
} from './confettiOptions';

@Component({
  selector: 'app-confetti',
  templateUrl: './confetti.component.html',
  styleUrls: ['./confetti.component.scss'],
})
export class ConfettiComponent implements OnInit, OnDestroy {
  subs: Subscription[] = [];

  particlesVisible = false;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  confettiOptions: any;

  constructor(
    private confettiService: ConfettiService,
    private readonly ngParticlesService: NgParticlesService
  ) {}

  ngOnInit(): void {
    this.ngParticlesService.init(async (engine: Engine) => {
      await loadFull(engine);
    });

    this.subs.push(
      this.confettiService.confettiType.subscribe((val) => {
        if (val === 'sides') {
          this.confettiOptions = confettiOptionsSides;
        } else if (val === 'bottom') {
          this.confettiOptions = confettiOptionsBottom;
        } else if (val === 'top') {
          this.confettiOptions = confettiOptionsTop;
        } else {
          this.confettiOptions = confettiOptionsDefault;
        }
      })
    );

    this.subs.push(
      this.confettiService.showConfettiNotifier.subscribe((value) => {
        this.particlesVisible = value;
      })
    );
  }

  // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-unused-vars
  particlesLoaded(container: Container): void {}

  // eslint-disable-next-line class-methods-use-this
  async particlesInit(): Promise<void> {
    await loadConfettiPreset(tsParticles);
  }

  ngOnDestroy() {
    let sub = this.subs.pop();
    while (sub) {
      sub.unsubscribe();
      sub = this.subs.pop();
    }
  }
}
